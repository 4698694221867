import { Button, Container, Group, Input, Paper, SimpleGrid, Stack, Text, Textarea, Tooltip } from '@mantine/core'
import { useForm } from '@mantine/form'
import React from 'react'
import { SortedProposals } from '../components/SortedProposals'
import { RemoteStateContext } from '../RemoteStateProvider'
import { IssueCard } from '../components/IssueCard'
import { IconBulb } from '@tabler/icons'

export function ProposalCollection() {
  const remoteState = React.useContext(RemoteStateContext)
  const textAreaRef = React.useRef(null)

  const form = useForm({
    initialValues: {
      description: '',
      name: '',
    },
  })

  return (
    <Container size={'xl'} style={{ minWidth: 1000 }}>
      <SimpleGrid cols={2} p={20}>
        <IssueCard />

        <Stack spacing={'md'} justify={'center'}>
          <Stack>
            <SortedProposals />
          </Stack>
          <form
            onSubmit={form.onSubmit((values) => {
              remoteState.addProposal(values)
              form.reset()
            })}
          >
            <Paper style={{ backgroundColor: 'rgba(255, 255, 255, 0.25)' }} radius={'md'} p={20}>
              <Stack align={'left'} justify={'center'}>
                <Group align="flex-end" spacing="xs">
                  <IconBulb size={30} color={'grey'} />
                  <Text weight={500}>Deine Lösung</Text>
                </Group>
                <Group position={'apart'} grow>
                  <Input.Wrapper id="pr oposal-name">
                    <Tooltip label={'Ein prägnanter Name deiner Lösung'}>
                      <Input id="proposal-name" required placeholder="Titel" maxLength={30} {...form.getInputProps('name')} />
                    </Tooltip>
                  </Input.Wrapper>
                </Group>

                <Input.Wrapper id="proposal-name" description="">
                  <Tooltip label={'Eine kurze Beschreibung deiner Lösung'}>
                    <Textarea required placeholder={'Beschreibung'} ref={textAreaRef} minRows={5} {...form.getInputProps('description')} />
                  </Tooltip>
                </Input.Wrapper>
                <AddProposalButton />
              </Stack>
            </Paper>
          </form>
        </Stack>
      </SimpleGrid>
    </Container>
  )
}

function AddProposalButton(): JSX.Element {
  return (
    <Container>
      <Button variant="gradient" gradient={{ from: 'teal', to: 'blue', deg: 60 }} type="submit">
        Vorschlag hinzufügen
      </Button>
    </Container>
  )
}
