import {
  ActionIcon,
  Button,
  Card,
  Col,
  Container,
  Divider,
  Grid,
  Group,
  Popover,
  Stack,
  Text,
  Textarea,
  useMantineTheme,
} from '@mantine/core'
import { useForm } from '@mantine/form'
import React, { Dispatch, MutableRefObject, SetStateAction, useState } from 'react'
import { Pencil } from 'tabler-icons-react'
import { IssueCard } from '../components/IssueCard'
import { SortedProposals } from '../components/SortedProposals'
import { Question, RemoteStateContext } from '../RemoteStateProvider'

export function InformationRound() {
  const remoteState = React.useContext(RemoteStateContext)
  const [question, setQuestion] = React.useState('')
  const textAreaRef = React.useRef(null)
  const theme = useMantineTheme()

  return (
    <Container size={'xl'} style={{ minWidth: 1000 }}>
      <Grid>
        <Col span={12}>
          <IssueCard />
        </Col>

        <Col span={6}>
          <Stack>
            <SortedProposals />
          </Stack>
        </Col>

        <Col span={6}>
          <Stack>
            {remoteState.questions.length === 0 && <Text style={{ color: theme.colors.gray[4] }}>Noch keine Fragen vorhanden.</Text>}
            {remoteState.questions.map((q) => (
              <AnswerModal question={q} answerQuestion={remoteState.answerQuestion} />
            ))}

            <Group>
              <Textarea
                onChange={(e) => {
                  setQuestion(e.target.value)
                }}
                ref={textAreaRef}
                placeholder="Welche inhaltliche Frage hast Du zu den Vorschlägen?"
                minRows={5}
                style={{ width: '100%' }}
              />
              <AddQuestionButton
                askQuestion={remoteState.askQuestion}
                description={question}
                textAreaRef={textAreaRef}
                setQuestion={setQuestion}
              />
            </Group>
          </Stack>
        </Col>
      </Grid>
    </Container>
  )
}

function AddQuestionButton({
  askQuestion,
  description: question,
  textAreaRef,
  setQuestion,
}: {
  askQuestion: (description: string) => Promise<void>
  description: string
  textAreaRef: MutableRefObject<null | HTMLTextAreaElement>
  setQuestion: Dispatch<SetStateAction<string>>
}): JSX.Element {
  return (
    <Container>
      <Button
        variant="gradient"
        gradient={{ from: 'teal', to: 'blue', deg: 60 }}
        onClick={() => {
          if (!question) {
            return
          }
          askQuestion(question)
          if (textAreaRef.current) {
            textAreaRef.current.value = ''
          }
          setQuestion('')
        }}
      >
        Frage stellen
      </Button>
    </Container>
  )
}

function AnswerModal({ question, answerQuestion }: { question: Question; answerQuestion: any }) {
  const [modalOpen, setModalOpen] = useState(false)
  const answerForm = useForm({
    initialValues: {
      answer: '',
      questionId: question.id,
    },
  })

  return (
    <Popover opened={modalOpen} key={question.id} width={200} position="bottom" withArrow shadow="md">
      <Popover.Target>
        <Card
          style={{
            overflowWrap: 'break-word',
          }}
        >
          <Grid>
            <Col span={1} style={{ margin: 'auto' }}>
              <ActionIcon size="xl" variant="light" onClick={() => setModalOpen(true)}>
                <Pencil size={20} />
              </ActionIcon>
            </Col>
            <Col span={11}>
              <Text weight={500} size="sm">
                {question.description}
              </Text>
            </Col>
            <Col span={12}>
              <Divider my="xs" label="Antworten" labelPosition="left" />
            </Col>
            <Col span={12}>
              <Text weight={300} size="md" align="left">
                {question.answer || 'Noch keine Antwort vorhanden'}
              </Text>
            </Col>
          </Grid>
        </Card>
      </Popover.Target>
      <Popover.Dropdown>
        <Stack>
          <form
            onSubmit={answerForm.onSubmit((values) => {
              answerQuestion(question.id, values.answer)
              answerForm.reset()
              setModalOpen(false)
            })}
          >
            <Textarea description="Deine Antwort" size="md" minRows={5} style={{ width: '100%' }} {...answerForm.getInputProps('answer')} />

            <Button type="submit">Beantworten</Button>
          </form>
        </Stack>
      </Popover.Dropdown>
    </Popover>
  )
}
