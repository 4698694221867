import { Box, Paper, Stack, Text, useMantineTheme } from '@mantine/core'
import React from 'react'
import { IssueCard } from '../components/IssueCard'
import { PassiveSolutionCard } from '../components/PassiveSolutionCard'

export function PropagateProblemStatement() {
  const theme = useMantineTheme()

  return (
    <Box
      sx={() => ({
        display: 'grid',
        gridTemplateColumns: '0.382fr 0.618fr ',
        gridTemplateRows: '1fr',
        width: '100vw',
        height: 'calc(100vh - 220px)',
        gap: '1em',
        padding: '1em',
      })}
    >
      <Box
        sx={(theme) => ({
          backgroundImage: `url(${process.env.PUBLIC_URL}/question.svg)`,
          backgroundPosition: 'left center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          backgroundAttachment: 'local',
          textAlign: 'center',
          padding: theme.spacing.xl,
          borderRadius: theme.radius.md,
        })}
      >
        <Paper style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }} radius={'xl'}>
          <Text style={{ color: theme.colors.gray[8] }} p={'2em'}>
            Der Moderator oder Anliegengeber teilt der Gruppe mit, <br />
            worum es geht und warum es wichtig ist, <br />
            eine Entscheidung zu treffen.{' '}
          </Text>
        </Paper>
      </Box>
      <Stack spacing={'md'} justify={'center'} mr={200}>
        <IssueCard />
        <PassiveSolutionCard />
      </Stack>
    </Box>
  )
}
