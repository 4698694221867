import { Group } from '@mantine/core'
import React from 'react'
import { DecisionStep, RemoteStateContext } from '../RemoteStateProvider'
import { FancyAvatar } from './FancyAvatar'

export function ParticipantsFooter() {
  const { currentParticipant: me, participants, activeSpeaker, step } = React.useContext(RemoteStateContext)

  return (
    <>
      <Group position={'center'} spacing="xs">
        {participants.map((p, index) => (
          <div style={{ pointerEvents: 'all' }} key={index}>
            <FancyAvatar
              name={p.name || 'Nutzer:in'}
              hasCrown={p.isModerator}
              hasMenu={p.id === me?.id}
              hasFocus={p.id === activeSpeaker?.id && step === DecisionStep.OPINION_ROUND}
              hasVoted={p?.hasVoted}
              isVotingInProgress={step === DecisionStep.VOTING}
              color={p.avatarColor}
              key={p.id}
            />
          </div>
        ))}
      </Group>
    </>
  )
}
