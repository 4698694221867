import { useAuth0 } from '@auth0/auth0-react'
import { Button, Center, Group, Stepper } from '@mantine/core'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { DecisionStep, RemoteStateContext } from '../RemoteStateProvider'

export function Navigation() {
  const remoteState = React.useContext(RemoteStateContext)
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth0();

  const mapping = new Map<DecisionStep, number>([
    [DecisionStep.PROPAGATE_PROBLEM_STATEMENT, 0],
    [DecisionStep.PROPOSAL_COLLECTION, 1],
    [DecisionStep.INFORMATION_ROUND, 2],
    [DecisionStep.OPINION_ROUND, 3],
    [DecisionStep.VOTING, 4],
    [DecisionStep.DECISION_MADE, 6],
  ])

  const activeStep = mapping.get(remoteState.step) || 0

  return (
    <Group>
      <Center style={{ width: '100%' }}>
        <Stepper active={activeStep} size='sm' style={{ margin: 'auto' }}>
          <Stepper.Step label="Entscheidungsbedarf verstehen" description="Warum ist diese Entscheidung wichtig?" color={'blue'} />
          <Stepper.Step label="Vorschläge sammeln" description="Was ist dein Vorschlag?" color={'blue'} />
          <Stepper.Step label="Informationen teilen" description="Welche Informationen fehlen?" color={'blue'} />
          <Stepper.Step label="Meinungsrunde" description="Was denkst du über den Entscheidungsbedarf?" color={'blue'} />
          <Stepper.Step label="Widerstandsabfrage" description="Wie hoch ist dein Widerstand?" color={'blue'} />
          <Stepper.Step label="Ergebnis" description=" " color={'blue'} />
        </Stepper>
        {activeStep === 6 &&
          <Button variant="gradient"
            gradient={{ from: 'teal', to: 'blue', deg: 60 }}
            onClick={() => navigate('/dashboard')}>
            {isAuthenticated ? 'Zum Dashboard' : 'Sitzung verlassen'}
          </Button>}
      </Center>
    </Group>
  )
}
