import {
  Button,
  Card,
  CloseButton,
  Col,
  Container,
  Divider,
  Grid,
  Group,
  Input,
  Loader,
  Text,
  Textarea,
  ThemeIcon
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { SiAngellist } from 'react-icons/si';
import { useNavigate } from 'react-router-dom';
import { useApi } from '../hooks/useApi';
import { PreparedProposal, RemoteStateContext } from '../RemoteStateProvider';
import { useTheme } from '../theme';

export function PrepareSesion() {
  const navigate = useNavigate();
  const [preparedProposals, setPreparedProposals] = React.useState([]);
  const queryClient = useQueryClient();

  const proposalDescriptionRef = React.useRef(null);
  const proposalNameRef = React.useRef(null);

  const { prepareDecision } = useApi();
  const { mutate: prepare, isLoading } = useMutation({
    mutationFn: prepareDecision,
    onSuccess: async () => { await queryClient.invalidateQueries({ queryKey: ['decisions'] }); navigate('/dashboard'); }
  });


  const form = useForm<FormValues>({
    initialValues: {
      issue: '',
      passiveSolution: '',
      proposals: new Array<PreparedProposal>()
    },
  })

  if (isLoading) {
    return <Loader variant='bars' size='xl' />
  }

  return (
    <>
      <form
        onSubmit={form.onSubmit((values) => {
          prepare({ ...values, proposals: preparedProposals });
        })}
      >
        <Container>
          <Grid>
            <Col span={6}>
              <Textarea
                required={true}
                placeholder="Worum geht es?"
                label="Anliegen"
                minRows={6}
                {...form.getInputProps('issue')}
              />
            </Col>
            <Col span={6}>
              <Textarea
                required={true}
                placeholder="Beschreibung der Ist-Situation. Was passiert, wenn ihr euch nicht aktiv entscheidet?"
                label="Passivlösung"
                minRows={6}
                {...form.getInputProps('passiveSolution')}
              />
            </Col>
            <Col span={12}>
              <Divider label="Vorschläge" labelPosition='center' />
            </Col>

            <Col span={6} style={{ paddingLeft: '1em', paddingRight: '1em' }}>
              <Grid>
                <Col span={12}>
                  <Input.Wrapper
                    id="proposal-name"
                    label="Titel"
                    description="Ein prägnanter Name deiner Idee"
                  >
                    <Input
                      id="proposal-name"
                      required
                      placeholder=""
                      ref={proposalNameRef}
                      maxLength={30}
                      {...form.getInputProps('name')}
                    />
                  </Input.Wrapper>
                </Col>
                <Col span={12}>
                  <Input.Wrapper
                    id="proposal-name"
                    label="Beschreibung"
                    description="Eine kurze Beschreibung deiner Idee"
                  >
                    <Textarea
                      required
                      ref={proposalDescriptionRef}
                      minRows={5}
                      {...form.getInputProps('description')}
                    />
                  </Input.Wrapper>
                </Col>
                <Col span={12}>
                  <AddProposalButton nameRef={proposalNameRef} descriptionRef={proposalDescriptionRef} preparedProposals={preparedProposals} setPreparedProposals={setPreparedProposals} />
                </Col>
              </Grid>
            </Col>

            <Col span={6}>
              <PreparedProposalTile setPreparedProposals={setPreparedProposals} preparedProposals={preparedProposals} />
            </Col>

            <Col span={12}>
              <Group position="center" style={{ width: '100%' }}>
                <Button
                  variant="gradient"
                  gradient={{ from: 'teal', to: 'blue', deg: 60 }}
                  size="md"
                  style={{ marginTop: '1em' }}
                  type="submit"
                >
                  Entscheidung anlegen
                </Button>
              </Group>
            </Col>
          </Grid>
        </Container>
      </form>
    </>
  );
}

interface FormValues {
  issue: string
  passiveSolution: string
  proposals: Array<PreparedProposal>
}

//@ts-ignore
function AddProposalButton({ nameRef, descriptionRef, preparedProposals, setPreparedProposals }): JSX.Element {
  return (
    <Container>
      <Button
        variant="gradient"
        gradient={{ from: 'teal', to: 'blue', deg: 60 }}
        size="md"
        style={{ marginTop: '1em' }}
        onClick={() => {
          const p = preparedProposals.concat({ name: nameRef.current.value, description: descriptionRef.current.value, createdAt: new Date() });
          nameRef.current.value = '';
          descriptionRef.current.value = '';
          setPreparedProposals(p)
        }}
      >
        Vorschlag hinzufügen
      </Button>
    </Container>
  );
}

//@ts-ignore
export function PreparedProposalTile({ preparedProposals, setPreparedProposals }) {
  const theme = useTheme();
  const remoteState = React.useContext(RemoteStateContext);

  return (
    <>
      {preparedProposals.map((p: PreparedProposal) => {

        return (
          <Card
            key={"proposalCard "}
            style={{
              marginTop: '0.2em',
              marginBottom: '0.2em',
              backgroundColor: theme.colors.gray[1],
            }}
          >
            <Grid>
              <Col span={1} style={{ margin: 'auto' }}>
                <ThemeIcon variant="light" size="lg">
                  <SiAngellist />
                </ThemeIcon>
              </Col>
              <Col span={10}>
                <Text weight={700} key={"proposalText "}>
                  {p.name}
                </Text>
                <Text size="sm">
                  {p.description}
                </Text>
              </Col>
              {remoteState.currentParticipant?.isModerator && (
                <Col span={1}>
                  <CloseButton
                    onClick={() => console.log("delete")}
                  ></CloseButton>
                </Col>
              )}
            </Grid>
          </Card>
        );

      })}

    </>
  );
}
