import {
  Button,
  Col,
  Container,
  Grid,
  Group,
  Image, Modal,
  Radio,
  Stack,
  Text,
  Textarea,
  TextInput,
  useMantineTheme
} from '@mantine/core'
import { useForm } from '@mantine/form'
import { showNotification } from '@mantine/notifications'
import { IconLink } from '@tabler/icons'
import React, { useState } from 'react'
import { Navigate, useParams } from 'react-router-dom'
import { v4 as uuid } from 'uuid'
import { FancyAvatar } from '../components/FancyAvatar'
import { API, DecisionStep, RemoteStateContext } from '../RemoteStateProvider'
import { generateRandomHslColorString } from '../utils/ColorHandling'

export function StartSession() {
  const remoteState = React.useContext(RemoteStateContext)

  const [sessionId, setSessionId] = React.useState('')

  const theme = useMantineTheme()
  const [opened, setOpened] = useState(false)
  const { preparedSessionId } = useParams();

  const form = useForm<FormValues>({
    initialValues: {
      participantName: `Kolleg:in-${Math.floor(Math.random() * 10)}`,
      avatarUrl: `https://i.pravatar.cc/300?u=${Math.floor(Math.random() * 50)}`,
      avatarColor: generateRandomHslColorString(),
      issue: '',
      passiveSolution: '',
    },
    validate: {
      participantName: (value) => (value && value.trim() !== '' ? null : 'Name ist ein Pflichtfeld.'),
    },
    validateInputOnChange: true,
  })

  React.useEffect(() => {
    if (preparedSessionId) {
      API.getPreparedDecisionById(preparedSessionId).then((pd) => {
        form.setFieldValue('issue', pd.issue);
        form.setFieldValue('passiveSolution', pd.passiveSolution);
      });
      setSessionId(preparedSessionId)
    } else {
      setSessionId(() => uuid())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (remoteState.step === DecisionStep.PROPAGATE_PROBLEM_STATEMENT) {
    return <Navigate to={`/decision/${sessionId}`} />
  }

  return (
    <>
      <form
        onSubmit={form.onSubmit((values) => {
          remoteState.startSession(sessionId, values)
        })}
      >
        <CallModeratorModal opened={opened} setOpened={setOpened} theme={theme} />

        <Container>
          <Grid>
            <Col span={12}>
              <Stack>
                <Group position={'center'}>
                  <Text size={14} weight={500}>
                    Dein Name
                  </Text>
                  <TextInput {...form.getInputProps('participantName')} />

                  {form.isValid() && <FancyAvatar name={form.values.participantName} color={form.values.avatarColor} />}
                </Group>

                <Textarea required={true} placeholder="Worum geht es?" label="Anliegen" minRows={3} {...form.getInputProps('issue')} />
                <Textarea
                  required={true}
                  placeholder="Beschreibung der Ist-Situation. Was passiert, wenn ihr euch nicht aktiv entscheidet?"
                  label="Passivlösung"
                  minRows={6}
                  {...form.getInputProps('passiveSolution')}
                />

                <Group position={'center'}>
                  <Radio.Group label="Wie komplex ist die Entscheidung?" defaultValue="2" required>
                    <Radio value="1" label="Einfach" />
                    <Radio value="2" label="Mittel" />
                    <Radio value="3" label="Komplex" />
                  </Radio.Group>
                </Group>
              </Stack>
            </Col>

            <Col span={12}>
              <Group position="center" style={{ width: '100%' }}>
                <Button
                  variant="gradient"
                  gradient={{ from: 'yellow', to: 'teal', deg: 60 }}
                  size="md"
                  style={{ marginTop: '1em' }}
                  onClick={() => setOpened(true)}
                >
                  Moderator anfordern
                </Button>
                <Button
                  variant="gradient"
                  gradient={{ from: 'teal', to: 'blue', deg: 60 }}
                  size="md"
                  style={{ marginTop: '1em' }}
                  type="submit"
                >
                  Jetzt entscheiden
                </Button>
              </Group>
            </Col>
          </Grid>
        </Container>
      </form>
    </>
  )
}

interface FormValues {
  issue: string
  passiveSolution: string
  participantName: string
  avatarUrl: string
  avatarColor: string
}

//@ts-ignore

function CallModeratorModal({ opened, setOpened, theme }) {
  const form = useForm({
    initialValues: {
      conferenceUrl: '',
    },
  })

  return (
    <Modal opened={opened} onClose={() => setOpened(false)}>
      {
        <>
          <Stack style={{ width: '100%' }}>
            <Image src={`${process.env.PUBLIC_URL}/moderator-support.svg`} style={{ maxWidth: '40%', margin: 'auto' }} />
            <Text align="center" style={{ fontSize: '2em' }}>
              Moderator bestellen
            </Text>
            <Text align="center" style={{ fontSize: '0.9em', color: theme.colors.gray[8] }}>
              Du brauchst einen Moderator? Wir sind gleich da! Schicke uns einen Link zu deiner Videokonferenz und ein erfahrener Moderator
              kommt in wenigen Minuten dazu.
            </Text>
            <form
              onSubmit={form.onSubmit((values) => {
                showNotification({
                  message: 'Moderator erfolgreich kontaktiert. Er schaltet sich in wenigen Minuten dazu.',
                  autoClose: 5000,
                  color: 'teal',
                })

                setOpened(false)
                form.reset()
              })}
            ></form>
            <TextInput label="Link zur Videokonferenz" icon={<IconLink size={14} />} {...form.getInputProps('conferenceUrl')} />
            <Button
              type="submit"
              variant="light"
              color="red"
              size="lg"
              onClick={() => {
                setOpened(false)
              }}
            >
              Moderator bestellen
            </Button>
          </Stack>
        </>
      }
    </Modal>
  )
}
