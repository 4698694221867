import { showNotification } from "@mantine/notifications"
import { IconX } from '@tabler/icons'
import React, { ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'
import { io } from 'socket.io-client'
import { v4 as uuid } from 'uuid'
import { GET_ACCESS_TOKEN } from './hooks/useAuthentication'

const socket = io(process.env.REACT_APP_API_URL || 'ws://localhost:3000', {
  reconnection: true,
  transports: ['websocket'],
})

export const API = {
  getInvite: (invite: string): Promise<{ status: string }> => {
    return emitEvent('get:invite', { invite });
  },

  getPreparedDecisions: (): Promise<Array<PreparedDecision>> => {
    return emitEvent('get:prepared-decisions', {});
  },

  getPreparedDecisionById: (decisionId: string): Promise<PreparedDecision> => {
    return emitEvent('get:prepared-decision', { id: decisionId });
  },
}

export interface Participant {
  id: string
  name: string
  avatarUrl: string
  avatarColor: string
  isModerator: boolean
  hasVoted: boolean
}

export interface Proposal {
  id: string
  createdAt: string
  name: string
  passiveSolution: boolean
  solutionNumber: number
  description: string
}

export interface Question {
  id: string
  description: string
  answer: string
}

export interface Result {
  id: string
  name: string
  passiveSolution: boolean
  solutionNumber: number
  description: string
  sum: number
  votes: ParticipantVote[]
  quality: ProposalQuality
}

export enum ProposalQuality {
  BEST = '1',
  GOOD = '2',
  AVERAGE = '3',
  BAD = '4',
  WORST = '5',
}

export interface DecisionSummary {
  startTimestamp: string
  endTimestamp: string
  questions: Question[]
  proposals: Result[]
  participants: ParticipantSummary[]
}
export interface ParticipantSummary {
  participantId: string
  participantName: string
  speechLog: SpeechLogEntry
  opinion: string
}

export interface SpeechLogEntry {
  totalSpeechTime: string
}
export interface ParticipantVote {
  participantId: string
  participantName: string
  rate: number
}

export interface Vote {
  proposalId: string
  rate: number
}

export interface PreparedDecision {
  issue: string;
  passiveSolution: string;
  proposals: PreparedProposal[];
}

export interface PreparedProposal {
  name: string;
  description: string;
}

export enum DecisionStep {
  DECISION_PREPARED = 'DECISION_PREPARED',
  PROPAGATE_PROBLEM_STATEMENT = 'PROPAGATE_PROBLEM_STATEMENT',
  INFORMATION_ROUND = 'INFORMATION_ROUND',
  OPINION_ROUND = 'OPINION_ROUND',
  PROPOSAL_COLLECTION = 'PROPOSAL_COLLECTION',
  VOTING = 'VOTING',
  DECISION_MADE = 'DECISION_MADE',
}

export interface RemoteState {
  startSession: (sessionId: string, formData: any) => Promise<void>
  joinSession: (sessionId: string, participantDetails: any) => Promise<void>
  startInformationRound: () => Promise<void>
  startOpinionRound: () => Promise<void>
  startProposalCollection: () => Promise<void>
  startVoting: () => Promise<void>
  addProposal: (proposal: any) => Promise<void>
  deleteProposal: (proposalId: string) => Promise<void>
  askQuestion: (question: string) => Promise<void>
  addVotes: (votes: Vote[], participantId: string) => Promise<void>
  endVoting: () => Promise<void>
  promptNextSpeaker: () => void
  participantStartsSpeaking: (particpantId: string) => void
  participantStoppedSpeaking: (particpantId: string) => void
  expressOpinion: (participantId: string, opinion: string) => void
  answerQuestion: (questionId: string, answer: string) => void
  prepareDecision: (preparedDecision: PreparedDecision) => void
  fakeInteruption: () => void

  inviteId: string
  issue: string
  participants: Participant[]
  activeSpeaker: Participant | null
  currentParticipant: Participant | null
  step: DecisionStep
  isReady: Boolean
  proposal: String
  proposals: Proposal[]
  questions: Question[]
  question: String
  votes: Vote[]
  summary: DecisionSummary
  interruption: boolean
  passiveSolution: string
}

const initialRemoteState = {
  startSession: (sessionId: string, formData: any) => {
    emitEvent('event:start-session', {
      sessionId: sessionId,
      participant: {
        name: formData.participantName,
        avatarUrl: formData.avatarUrl,
        avatarColor: formData.avatarColor,
        isModerator: true,
      },
      issue: formData.issue,
      passiveSolution: formData.passiveSolution,
    })

    return Promise.resolve()
  },
  joinSession: (sessionId: string, participant: any) => {
    emitEvent('event:join-session', {
      sessionId: sessionId,
      participant: participant,
    })

    return Promise.resolve()
  },
  startProposalCollection: () => {
    emitEvent('event:start-proposal-collection', {})

    return Promise.resolve()
  },
  startInformationRound: () => {
    emitEvent('event:start-information-round', {})

    return Promise.resolve()
  },
  startOpinionRound: () => {
    emitEvent('event:start-opinion-round', {})

    return Promise.resolve()
  },
  startVoting: () => {
    emitEvent('event:start-voting', {})

    return Promise.resolve()
  },
  endVoting: () => {
    emitEvent('event:end-voting', {})

    return Promise.resolve()
  },
  addProposal: ({ name, description }: { name: string; description: string }) => {
    emitEvent('event:add-proposal', {
      name: name,
      description: description,
    })

    return Promise.resolve()
  },
  deleteProposal: (proposalId: string) => {
    emitEvent('event:delete-proposal', {
      proposalId: proposalId,
    })

    return Promise.resolve()
  },
  addVotes: (votes: Vote[], participantId: string) => {
    emitEvent('event:add-vote', {
      participantId: participantId,
      votes: votes,
    })

    return Promise.resolve()
  },
  askQuestion: (question: string) => {
    emitEvent('event:ask-question', {
      description: question,
    })

    return Promise.resolve()
  },
  promptNextSpeaker: () => {
    emitEvent('event:prompt-next-speaker', {})
  },
  participantStartsSpeaking: (participantId: string) => {
    emitEvent('event:participant-starts-speaking', {
      participantId: participantId,
      eventTime: new Date(),
    })
  },
  participantStoppedSpeaking: (participantId: string) => { },
  expressOpinion: (participantId: string, opinion: string) => {
    emitEvent('event:express-opinion', {
      participantId: participantId,
      opinion: opinion,
    })
  },
  answerQuestion: (questionId: string, answer: string) => {
    emitEvent('event:answer-question', {
      questionId: questionId,
      answer: answer,
    })
  },
  prepareDecision: (preparedDecision: PreparedDecision) => {
    emitEvent('event:prepare-decision', preparedDecision)
  },
  fakeInteruption: () => {
    emitEvent('event:participant-interrupted', {});
  },

  inviteId: '',
  issue: '',
  participants: [],
  activeSpeaker: null,
  currentParticipant: null,
  proposal: '',
  proposals: [],
  questions: [],
  question: '',
  step: DecisionStep.DECISION_PREPARED,
  isReady: false,
  votes: [],
  summary: {} as DecisionSummary,
  interruption: false,
  passiveSolution: '',
}

async function emitEvent(name: string, content: any): Promise<any> {
  const rejoinRoom = async () => {
    const sessionId = window.location.toString().split('/decision/').length === 2 ? window.location.toString().split('/decision/')[1] : '';
    if (sessionId) {
      await new Promise<void>((resolve) => {
        socket.emit('event:rejoin-room', { sessionId }, () => resolve())
      });
    }
  }
  const accessToken = GET_ACCESS_TOKEN ? await GET_ACCESS_TOKEN({ audience: "https://api.decide.social", scope: "openid profile email offline_access app_data" }) : null;
  const socketAuth = socket.auth as { [key: string]: any }

  if (accessToken) {
    const setInitalAuthn = accessToken && !socketAuth
    const refreshAuthn = accessToken && socketAuth && socketAuth['token'] !== accessToken

    if (setInitalAuthn || refreshAuthn) {
      console.debug('Establishing authenticated socket connection.');
      socket.auth = { token: accessToken };
      socket.disconnect().connect();
      rejoinRoom();
    }
  } else {
    let anonymousClientId = localStorage.getItem("ANONYMOUS_CLIENT_ID");
    if (!anonymousClientId) {
      anonymousClientId = uuid();
      localStorage.setItem("ANONYMOUS_CLIENT_ID", anonymousClientId);
    }
    const socketHasToken = socketAuth && socketAuth['token'];
    const socketHasSameClientId = socketAuth && socketAuth['anonymousClientId'] === anonymousClientId;
    if (!socketHasToken && !socketHasSameClientId) {
      console.debug('Establishing socket connection with anonymous client id.');
      socket.auth = { anonymousClientId };
      socket.disconnect().connect();
      rejoinRoom();
    }
  }

  if (!socket.connected) {
    socket.connect();
    rejoinRoom();
  }

  return new Promise((resolve) => {
    socket.emit(name, content, (response: any) => {
      resolve(response);
    })
  });
}

export const RemoteStateContext = React.createContext<RemoteState>(initialRemoteState)

export function RemoteStateProvider(props: { children: ReactElement }) {
  const [remoteState, setRemoteState] = React.useState<RemoteState>(initialRemoteState)

  const navigate = useNavigate()

  React.useEffect(() => {
    socket.on('event:session-started', (response) => {
      setRemoteState((state) => ({
        ...state,
        participants: response.participants,
        currentParticipant: response.startingParticipant,
        step: response.step,
        issue: response.issue,
        passiveSolution: response.passiveSolution,
      }))

      localStorage.setItem('participantId', response.startingParticipant.id)
    })
    socket.on('event:participant-joined', (response) => {
      if (!remoteState.currentParticipant) {
        setRemoteState((state) => ({
          ...state,
          participants: response.sessionState.participants,
          currentParticipant: response.joiningParticipant,
          step: response.sessionState.step,
          issue: response.sessionState.issue,
          questions: response.sessionState.questions,
          proposals: response.sessionState.proposals,
          summary: response.sessionState.summary,
          passiveSolution: response.sessionState.passiveSolution,
          activeSpeaker: response.sessionState.participants.find((p: any) => p.speaker)
        }))
        localStorage.setItem('participantId', response.joiningParticipant.id)
      } else {
        setRemoteState((state) => ({
          ...state,
          participants: response.sessionState.participants,
        }))
      }
    })
    socket.on('event:decision-step-changed', (session) => {
      setRemoteState((state) => ({
        ...state,
        step: session.step,
      }))
    })
    socket.on('event:proposals-updated', (response) => {
      setRemoteState((state) => ({
        ...state,
        proposals: response.proposals,
      }))
    })
    socket.on('event:questions-updated', (response) => {
      setRemoteState((state) => ({
        ...state,
        questions: response.questions,
      }))
    })
    socket.on('event:question-answered', (response) => {
      setRemoteState((state) => {
        const questions = state.questions.map((q) => {
          if (q.id === response.questionId) {
            q.answer = response.answer
          }
          return q
        })

        return {
          ...state,
          questions: questions,
        }
      })
    })
    socket.on('event:voting-ended', (response) => {
      setRemoteState((state) => ({
        ...state,
        summary: response,
      }))
    })
    socket.on('event:next-speaker-prompted', (response) => {
      setRemoteState((state) => ({
        ...state,
        activeSpeaker: response.speaker,
      }))
    })
    socket.on('event:current-session-state', (session) => {
      setRemoteState((state) => ({
        ...state,
        participants: session.participants,
        currentParticipant: !state.currentParticipant ? session.currentParticipant : state.currentParticipant,
        step: session.step,
        issue: session.issue,
      }))
    })
    socket.on('event:participant-interrupted', (response) => {
      setRemoteState((state) => {

        setTimeout(() => {
          setRemoteState((state) => ({ ...state, interruption: false }));
        }, 2500);
        return {
          ...state,
          interruption: true,
        }
      });
    });
    socket.on('event:participant-voted', (response: { participantId: string }) => {
      const affectedParticipant = remoteState.participants
        .find(participant => participant.id === response.participantId);
      if (affectedParticipant) {
        const participants = remoteState.participants.map(participant => {
          if (participant.id === affectedParticipant.id) {
            participant.hasVoted = true;
          }
          return participant;
        })
        setRemoteState((state) => ({ ...state, participants }));
      }
    });

    socket.io.on('reconnect', () => {
      socket.emit(
        'event:sync-session',
        {
          participantId: localStorage.getItem('participantId'),
        },
        (answer: any) => {
          if (!answer.found) {
            console.log('Unable to sync session state. Logout.')
            localStorage.clear()
            setRemoteState((state) => ({ ...initialRemoteState }))

            if (remoteState.inviteId) {
              navigate(`/?invite=${remoteState.inviteId}`)
            } else {
              navigate(`/`)
            }
          }
        },
      )
    });

    socket.on('connect', () => {
      socket.sendBuffer = []
    });

    socket.on('connection_error', (err) => {
      showNotification({
        message: 'Es ist ein Verbindungsfehler aufgetreten. Bitte laden Sie die Seite neu. Falls der Fehler weiterhin besteht, kontaktieren Sie bitte den Support unter support@2iterate.de',
        color: 'red',
        icon: <IconX />,
        autoClose: 5000
      }
      );
    });

    socket.on('exception', (err) => {
      console.log(`Es ist ein Fehler aufgetreten: ${JSON.stringify(err)}`)
      showNotification({
        message: 'Es ist ein Fehler aufgetreten. Bitte laden Sie die Seite neu. Falls der Fehler weiterhin besteht, kontaktieren Sie bitte den Support unter support@2iterate.de',
        color: 'red',
        icon: <IconX />,
        autoClose: 10000
      }
      );
    });

    return () => {
      socket.removeAllListeners()
    }
  }, [navigate, remoteState.inviteId, remoteState.currentParticipant, remoteState.participants])

  return <RemoteStateContext.Provider value={remoteState}>{props.children}</RemoteStateContext.Provider>
}
