import { Box, Button, Card, Col, Container, Grid, Group, ScrollArea, SimpleGrid, Stack, Table, Text } from '@mantine/core'
import React from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Activity, Bulb, Star } from 'tabler-icons-react'
import { ResistanceChart } from '../components/ResistanceChart'
import { SpeechDistributionChart } from '../components/SpeechDistributionChart'
import { ProposalQuality, RemoteStateContext } from '../RemoteStateProvider'
import { DecisionSummary } from './DecisionSummary'
import { IconBolt } from '@tabler/icons'
import { usePreview } from '../hooks/usePreview'

export function DecisionMade() {
  const remoteState = React.useContext(RemoteStateContext)
  const {previewMode} = usePreview();
  const { sessionId } = useParams()

  const navigate = useNavigate()
  const decisionSummary = new DecisionSummary(remoteState)
  const proposals = decisionSummary.proposals()

  const qualityColorRange = ['green', 'teal', 'orange', 'red', 'red']

  const decisionQualityValue = parseInt(decisionSummary.acceptedProposal().quality)

  return (
    <Container size={'xl'}>
      <Grid>
        <Col span={12}>
          <Group align="flex-end">
            <Group align="flex-end" spacing="xs">
              <IconBolt size={32} color={'grey'} />
              <Text weight={500}>Anliegen</Text>
            </Group>
            <Text align={'left'}>{remoteState.issue}</Text>
          </Group>
        </Col>
        <Col span={6}>
          <Grid>
            <Col span={12}>
              <Stack>
                <Card radius={'md'} shadow={'md'}>
                  <Card.Section>
                    <Box style={{ background: '#7974ab' }} p={10}>
                      <Group>
                        <Bulb size={32} strokeWidth={2} color={'#eee3d4'} />
                        <Text align="center" color={'white'} size={'md'}>
                          Akzeptierte Lösung
                        </Text>
                      </Group>
                    </Box>
                  </Card.Section>

                  <Group>
                    <Stack>
                      <Text align="left" size={'md'} mt="md" mb="xs">
                        {decisionSummary.acceptedProposal().name}
                      </Text>
                      <Text align="left" color={'dimmed'}>
                        {decisionSummary.acceptedProposal().description}
                      </Text>
                    </Stack>
                  </Group>
                </Card>
              </Stack>
            </Col>
            <Col span={12}>
              <SimpleGrid cols={4}>
                <Card>
                  <Text size={'md'}>{decisionSummary.elapsedTime()} Minuten</Text>
                  <Text size={'sm'}>Dauer</Text>
                </Card>
                <Card>
                  <>
                    {decisionSummary.acceptedProposal().quality === ProposalQuality.WORST ? (
                      <>
                        <Star color={'red'} size={20} />
                        <Star color={'red'} size={20} />
                        <Star color={'red'} size={20} />
                        <Star color={'red'} size={20} />
                        <Star color={'red'} size={20} />
                      </>
                    ) : (
                      <>
                        {[...Array(5 - decisionQualityValue)].map(() => (
                          <Star color={qualityColorRange.at(decisionQualityValue - 1)} size={20} />
                        ))}
                        {[...Array(decisionQualityValue)].map(() => (
                          <Star color={'#ccc'} size={20} />
                        ))}
                      </>
                    )}
                  </>

                  <Text size={'sm'}>Akzeptanz</Text>
                </Card>
                <Card>
                  <Text size={'md'}>{decisionSummary.acceptedProposal().sum}</Text>
                  <Text size={'sm'}>Gruppenwiderstand</Text>
                </Card>
                <Card>
                  <Text size={'md'}>{decisionSummary.acceptedProposal().sumAverage}</Text>
                  <Text size={'sm'}>Ø Widerstand</Text>
                </Card>
                <Card>
                  <Text size={'md'}>{decisionSummary.questions().length}</Text>
                  <Text size={'sm'}>Fragen</Text>
                </Card>
                <Card>
                  <Text size={'md'}>{proposals.length}</Text>
                  <Text size={'sm'}>Vorschläge</Text>
                </Card>
                <Card>
                  <Text size={'md'}>{decisionSummary.participants().length}</Text>
                  <Text size={'sm'}>Teilnehmer</Text>
                </Card>
                <Card>
                  <Text size={'md'}>1</Text>
                  <Text size={'sm'}>Unterbrechungen</Text>
                </Card>
              </SimpleGrid>
            </Col>
          </Grid>
        </Col>

        <Col span={6}>
          <ResistanceChart decisionSummary={decisionSummary} />
        </Col>
       
        { previewMode &&  <>
          <Col span={6}>
          <Card>
            <Group spacing={'xl'}>
              <Activity color={'purple'} />
              <Text size={'md'}>Impulse</Text>

              <Text align="left" style={{ fontSize: '1.1em' }}>
                Erneute Abstimmung <Link to="#">starten.</Link>
              </Text>

              <Text align="left" style={{ fontSize: '1.1em' }}>
                Rene hat einen vergleichsweise hohen Widerstand, aber in der Meinungrunde wenig gesagt.
              </Text>

              <Text align="left" style={{ fontSize: '1.1em' }}>
                Während der Entscheidung wurde 1x mal ein Sprechender unterbrochen. Gebt Euch Raum Eure Gedanken zu äußern und probiert euch
                beim nächsten Mal in der Technik der <Link to="#">stillen Präsenz.</Link>
              </Text>

              <Text align="left" style={{ fontSize: '1.1em' }}>
                Vielleicht macht eine erneute <Link to="#">Meinungsrunde</Link> Sinn? Oder möchtet ihr die Entscheidungstechnik{' '}
                <Link to="#">wechseln</Link>?
              </Text>
            </Group>
          </Card>
        </Col>
        <Col span={6}>
          <SpeechDistributionChart />
        </Col>
        </>
        }
       
        <Col span={12}>
          <Button
            variant="gradient"
            gradient={{ from: 'teal', to: 'blue', deg: 60 }}
            style={{ marginTop: '3em' }}
            onClick={() => {
              navigate(`/decision/${sessionId}/details`)
            }}
          >
            Details...
          </Button>
        </Col>
      </Grid>
    </Container>
  )
}

interface ProposalTableProps {
  data: {
    name: string
    sum: number
    sumRelative: number
  }[]
}

export function ProposalTable({ data }: ProposalTableProps) {
  const rows = data.map((row) => {
    return (
      <tr key={row.name}>
        <td>
          <Text style={{ textAlign: 'left' }}>{row.name}</Text>
        </td>
        <td>
          <Text style={{ textAlign: 'left' }}>{row.sum}</Text>
        </td>
        <td>
          <Text style={{ textAlign: 'left' }}>{row.sumRelative}%</Text>
        </td>
      </tr>
    )
  })

  return (
    <ScrollArea>
      <Table verticalSpacing="xs" highlightOnHover>
        <thead>
          <tr>
            <th>Name</th>
            <th>Summe Widerstand</th>
            <th>Widerstand (%)</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </ScrollArea>
  )
}

function selectColorFor(index: number) {
  return ['#0D3B66', '#FAF0CA', '#F4D35E', '#EE964B', '#F95738', '#B3C0A4', '#54428E'][index]
}
