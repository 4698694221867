import { Modal, Image, SimpleGrid, Text } from "@mantine/core";
import React from "react";

export function AskPermissionModal({ opened }: { opened: boolean }) {
    return (
        <Modal centered withCloseButton size="xl" opened={opened} onClose={() => { }}>
            <SimpleGrid cols={2} spacing="xl">
                <Image src={`${process.env.PUBLIC_URL}/preferences_popup.svg`} />
                <div>
                    <Text size="xl" weight={700}>Mikrofon aktivieren</Text>
                    <Text>Um die Qualität der getroffenen Entscheidungen zu bewerten ist es wichtig die Redebeteiligung der einzelnen Teilnehmer zu erfassen. Dazu benötigen wir die Erlaubniss auf das Mikrofon des Gerätes zuzugreifen.</Text>
                    <Text weight={700}>Es erfolgt keine Aufzeichnung oder inhatliche Auswertung der erfassten Daten.</Text>
                    <Text>Die Anwendung kann auch ohne Mikrofon-Berechtigung zum Treffen von Entscheidungen genutzt werden.</Text>
                </div>
            </SimpleGrid>
        </Modal>
    );
}