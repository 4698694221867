import { useAuth0, User } from '@auth0/auth0-react'
import { Button, Center, Container, Grid, Group, Loader, Text, TextInput } from '@mantine/core'
import { useForm } from '@mantine/form'
import React from 'react'
import { useParams } from 'react-router-dom'
import { FancyAvatar } from '../components/FancyAvatar'
import { Participant } from '../components/ParticipantCard'
import { SET_GET_ACCESS_TOKEN } from '../hooks/useAuthentication'
import { RemoteStateContext } from '../RemoteStateProvider'
import { generateRandomHslColorString } from '../utils/ColorHandling'
import { SessionLayout } from './SessionLayout'

export function JoinSession() {
  const remoteState = React.useContext(RemoteStateContext)

  return (
    <>
      {remoteState.participants.length < 1 ? (
        <Center style={{ marginTop: '1em' }}>
          <JoinForm />
        </Center>
      ) : (
        <SessionLayout />
      )}
    </>
  )
}

function JoinForm() {
  const { sessionId } = useParams()
  const remoteState = React.useContext(RemoteStateContext)
  const [isLoading, setIsLoading] = React.useState(true);
  const { getAccessTokenSilently, user, loginWithRedirect } = useAuth0();

  const login = () => loginWithRedirect({ redirectUri: `${window.location.origin}/login/redirect_uri`, appState: { redirectTarget: window.location.pathname } })

  React.useEffect(() => {
    const checkIfUsesIsLoggedIn = async () => {
      try {
        //If we can get a token without an error the user is still logged in.
        await getAccessTokenSilently({ redirect_uri: `${window.location.origin}/login/redirect_uri` });
        //If the user is still logged in, we need to bind the global GET_ACCESS_TOKEN function to the
        //Auth0 instance to make it accessible outside of the React managed stat.
        SET_GET_ACCESS_TOKEN(getAccessTokenSilently);
        console.debug(`User is authenticated.`);
      } catch {
        console.debug(`User is anonymous.`);
      } finally {
        setIsLoading(false);
      }
    };
    checkIfUsesIsLoggedIn();
  }, [getAccessTokenSilently]);



  if (isLoading) {
    return <Loader />
  }

  return (
    <Container>
      <Text weight={800}>Du bist dabei, einer bestehenden decide.social Sitzung beizutreten.</Text>
      {user ?
        <EnterUserNameForm user={user} onSubmit={(values) => remoteState.joinSession(sessionId || '', values)} /> :
        <Grid align="stretch" style={{ minHeight: '60vh' }}>
          <Grid.Col span={5}>
            <div style={{ marginTop: '50%' }}>
              <Text weight={500}>Wenn du bereits einen Account für decide.social besitzt, logge dich ein um alle deine Entscheidungen zu dokumentieren.</Text>
              <Button onClick={login} variant="gradient" gradient={{ from: 'grape', to: 'blue', deg: 60 }} size="xl" style={{ marginTop: '6.6em' }}>
                Login
              </Button>
            </div>
          </Grid.Col>
          <Grid.Col span={2}></Grid.Col>
          <Grid.Col span={5}>
            <div style={{ marginTop: '50%' }}>
              <Text weight={500}>Falls du noch keinen Account besitzt, kannst du als Gast an dieser Entscheidung mitwirken.</Text>
              <EnterUserNameForm user={user} onSubmit={(values) => remoteState.joinSession(sessionId || '', values)} />
            </div>
          </Grid.Col>
        </Grid>}
    </Container>
  )
}
function EnterUserNameForm({ user, onSubmit }: { user?: User, onSubmit: (value: Participant) => void }) {
  const randomAvatarId = Math.floor(Math.random() * 10)

  const form = useForm<Participant>({
    initialValues: {
      id: '',
      name: `Kolleg:in ${randomAvatarId}`,
      avatarUrl: `https://i.pravatar.cc/300?u=${randomAvatarId}`,
      avatarColor: generateRandomHslColorString(),
      isModerator: false,
    },
    validate: {
      name: (value) => (value && value.trim() !== '' ? null : 'Name ist ein Pflichtfeld.'),
    },
    validateInputOnChange: true,
  })


  if (user?.name) {
    form.values.name = user.name;
  }

  return <form onSubmit={form.onSubmit(onSubmit)}>
    <Group position={'center'}>
      <Text size={14} weight={500}>
        Dein Name
      </Text>
      <TextInput disabled={user ? true : false} {...form.getInputProps('name')} />
      {form.isValid() && <FancyAvatar name={form.values.name} color={form.values.avatarColor} />}
    </Group>

    <Button variant="gradient" gradient={{ from: 'teal', to: 'blue', deg: 60 }} size="xl" style={{ marginTop: '3em' }} type="submit">
      Teilnehmen
    </Button>
  </form>
}

