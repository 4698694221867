import {
  ActionIcon, Button,
  Card,
  Center,
  Col,
  Grid,
  Group, Loader, Stack,
  Text,
  Tooltip
} from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconX } from '@tabler/icons';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { Archive, Rocket } from 'tabler-icons-react';
import { useApi } from '../hooks/useApi';
import { usePreview } from '../hooks/usePreview';
import { useTheme } from '../theme';

export function Dashboard() {
  const navigate = useNavigate();
  const { previewMode } = usePreview();
  const { getDecisions } = useApi();
  const decisionsQuery = useQuery(['decisions'], getDecisions);

  if (decisionsQuery.isError) {
    showNotification({
      message: 'Es ist ein Fehler aufgetreten. Bitte laden Sie die Seite neu. Falls der Fehler weiterhin besteht, kontaktieren Sie bitte den Support unter support@2iterate.de',
      color: 'red',
      icon: <IconX />,
      autoClose: 10000
    }
    );
  }

  return (
    <Stack style={{ minWidth: '60%' }}>
      {decisionsQuery.isLoading ? <Center><Loader variant='bars' size='xl' /></Center> : <DecisionsOverview decisons={decisionsQuery.data || []} />}
      <Center style={{ marginTop: '2em' }}>
        <Group>
          <Button onClick={() => { navigate("/decision/prepare") }}>Entscheidung vorbereiten</Button>
          <Button onClick={() => { navigate(`/decision/start/${previewMode ? "?preview=true" : ""}`) }}>Entscheidung sofort treffen</Button>
        </Group>
      </Center>

    </Stack>
  );
}

function DecisionsOverview({ decisons }: { decisons: { id: string, issue: string, step: string }[] }) {
  const preparedDecissions = decisons.filter(d => d.step === 'DECISION_PREPARED') || [];
  const madeDecissions = decisons.filter(d => d.step === 'DECISION_MADE') || [];

  return (
    <Grid>
      <Grid.Col span={6}>
        <Text weight={500}>Vorbereitete Entscheidungen</Text>
        {preparedDecissions.map((pd) => <PreparedDecisionCard decision={pd} key={pd.id} />)}
      </Grid.Col>
      <Grid.Col span={6}>
        <Text weight={500}>Getroffene Entscheidungen</Text>
        {madeDecissions.map((pd) => <MadeDecisionCard decision={pd} key={pd.id} />)}
      </Grid.Col>
    </Grid>
  );
}

function PreparedDecisionCard({ decision }: { decision: { id: string, issue: string, step: string } }) {
  const navigate = useNavigate();
  const { previewMode } = usePreview();

  return <DecisionCard
    decision={decision}
    actionIcon={
      <Tooltip label='Entscheidung starten' position='left'>
        <ActionIcon variant="light" size="lg" onClick={() => {
          navigate("/decision/start/" + decision.id + (previewMode ? "&preview=true" : ""));
        }}>
          <Rocket />
        </ActionIcon>
      </Tooltip>
    } />
}

function MadeDecisionCard({ decision }: { decision: { id: string, issue: string, step: string } }) {
  const navigate = useNavigate();

  return <DecisionCard
    decision={decision}
    actionIcon={
      <Tooltip label='Entscheidung anzeigen' position='left'>
        <ActionIcon variant="light" size="lg" onClick={() => {
          navigate(`/decision/${decision.id}`);
        }}>
          <Archive />
        </ActionIcon>
      </Tooltip>
    } />
}

function DecisionCard({ decision, actionIcon }: { decision: { id: string, issue: string, step: string }, actionIcon: JSX.Element }) {
  const theme = useTheme();

  return (
    <Card
      key={"preparedDecision" + decision.id}
      style={{
        marginTop: '0.2em',
        marginBottom: '0.2em',
        backgroundColor: theme.colors.gray[1],
      }}
    >
      <Grid>

        <Col span={10}>
          <Text weight={700} key={"name "}>
            {decision.issue}
          </Text>
        </Col>
        <Col span={1} style={{ margin: 'auto' }}>
          {actionIcon}
        </Col>
      </Grid>
    </Card>
  );
}