import React from 'react'
import { RemoteStateContext } from '../RemoteStateProvider'
import { ActionIcon, Group, Paper, Stack, Text, Tooltip } from '@mantine/core'
import { IconBulb } from '@tabler/icons'
import { InfoCircle } from 'tabler-icons-react'

export function PassiveSolutionCard() {
  const remoteState = React.useContext(RemoteStateContext)

  return (
    <Paper p={'md'}>
      <Stack justify={'center'}>
        <Group position={'apart'}>
          <Group align="flex-end" spacing="xs">
            <IconBulb size={30} stroke={1.5} color={'grey'} />
            <Text weight={500} align={'left'}>
              Passivlösung
            </Text>
          </Group>

          <Tooltip
            multiline
            width={300}
            transition="fade"
            transitionDuration={200}
            label="Die Passivlösung beschreibt den Status Quo, der Fall wenn von euch keine aktive Entscheidung getroffen wird – alles bleibt
            wie es ist."
          >
            <ActionIcon variant={'transparent'}>
              <InfoCircle size={30} />
            </ActionIcon>
          </Tooltip>
        </Group>
        <Text align={'left'}>{remoteState.passiveSolution}</Text>
      </Stack>
    </Paper>
  )
}
