import { Avatar, Group, Menu, Space, Tooltip, UnstyledButton } from '@mantine/core'
import React from 'react'
import { CircleCheck, Crown, HourglassHigh, Logout, Settings } from 'tabler-icons-react'
import { css, Global } from '@emotion/react'

export type FancyAvatarProps = {
  name: string
  hasCrown?: boolean
  hasMenu?: boolean
  hasFocus?: boolean
  color?: string | null
  hasVoted?: boolean
  isVotingInProgress?: boolean
}

export const FancyAvatar: React.FC<FancyAvatarProps> = (props) => {
  const { name, hasCrown, hasMenu, hasFocus, color, hasVoted, isVotingInProgress } = props

  let colorToUse = 'hsl(322 100 50%)' //pink fallback

  if (!!color) {
    colorToUse = color
  }

  const label = maybeGenerateNameInitials(name)

  return (
    <>
      <div className={'grid'}>
        <div className="above">{hasCrown && <Crown fill={colorToUse} strokeWidth={0} />}</div>
        <div className="center">
          <Tooltip label={name} withArrow position={'top'}>
            <Group>
              <Avatar
                gradient={{ from: colorToUse, to: colorToUse }}
                variant={'gradient'}
                size={hasFocus && !isVotingInProgress ? 90 : 50}
                radius={50}
              >
                {label}
              </Avatar>
            </Group>
          </Tooltip>
        </div>
        <div className="below">
          {isVotingInProgress && !hasVoted && (
            <>
              <Space h={'xs'} />
              <HourglassHigh color={'#888'} />
            </>
          )}
          {isVotingInProgress && hasVoted && (
            <>
              <Space h={'xs'} />
              <CircleCheck color={'purple'} />
            </>
          )}
          {hasMenu && !isVotingInProgress && (
            <Menu shadow="md" position={'top'} trigger="hover">
              <Menu.Target>
                <UnstyledButton>
                  <Space h={'xs'} />
                  <Settings color={'#555'} size={20} />
                </UnstyledButton>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Label>Deine Einstellungen</Menu.Label>
                <Menu.Divider />
                <Menu.Item icon={<Logout size={20} />} onClick={() => (window.location.href = '/dashboard')}>
                  Sitzung verlassen
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          )}
        </div>
      </div>
      <Global
        styles={css`
          .grid {
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 17px 1fr 30px;
            gap: 0 0;
            grid-template-areas:
              'above'
              'center'
              'below';
            justify-content: center;
          }

          .above {
            align-self: baseline;
            grid-area: above;
          }

          .center {
            grid-area: center;
          }

          .below {
            grid-area: below;
          }
        `}
      />
    </>
  )
}

function maybeGenerateNameInitials(participantName: string): string | null {
  if (!participantName) return null

  let avatarLabel = participantName ? participantName.replace(/[^A-Z]/g, '') : null

  if (avatarLabel && avatarLabel.length > 2) {
    avatarLabel = avatarLabel[0] + avatarLabel[1]
  }

  if (!avatarLabel && participantName.length > 0) {
    avatarLabel = participantName[0].toUpperCase()
  }

  return avatarLabel
}
