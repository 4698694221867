import { css, Global } from "@emotion/react";
import { Loader, Text } from "@mantine/core";
import React from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { Dashboard } from "../pages/Dashboard";
import { DecisionDetails } from "../pages/DecisionDetails";
import { JoinSession } from "../pages/JoinSession";
import { LandingPage } from "../pages/LandingPage";
import { PrepareSesion } from "../pages/PrepareDecision";
import { StartSession } from "../pages/StartSession";
import { useAuthentication } from "../hooks/useAuthentication";
import { LoginRedirect } from "../pages/LoginRedirect";

export function DecideSocialRoutes() {
    return (
        <Routes>
            <Route path="/login/redirect_uri" element={<LoginRedirect />} />
            <Route path="/" element={<Layout><LandingPage /></Layout>} />
            <Route path="/decision/:sessionId" element={<Layout><JoinSession /></Layout>} />
            <Route element={<ProtectedRoute />}>
                <Route element={<Layout />}>
                    <Route
                        path="/dashboard"
                        element={<Dashboard />}
                    />
                    <Route
                        path="/decision/start/:preparedSessionId"
                        element={<StartSession />}
                    />
                    <Route
                        path="/decision/start"
                        element={<StartSession />}
                    />
                    <Route
                        path="/decision/prepare"
                        element={<PrepareSesion />}
                    />

                    <Route
                        path="/decision/:sessionId/details"
                        element={<DecisionDetails />}
                    />
                </Route>
            </Route>
        </Routes>
    );
}

function Layout({ children }: { children?: JSX.Element }): JSX.Element {
    return (
        <div
            className="App"
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                minWidth: '100vw',
                minHeight: '100vh',
            }}
        >
            {children ? children : <Outlet />}
            <Global
                styles={css`
            .App {
              background-image: linear-gradient(to top, #ebe7f1 0%, #eaf1fb 95%, #ffffff 100%);
            }
          `}
            />
        </div>
    )
}

function ProtectedRoute() {
    const { isLoading, canProgress } = useAuthentication();

    if (isLoading) {
        return <LoginLoading />
    }

    if (!canProgress) {
        return <Navigate to="/" replace />
    }

    return <Outlet />
}

function LoginLoading() {
    const texts = [
        "Sicheres Einloggen...",
        "Lade Nutzerdaten...",
        "Dashboard vorbereiten...",
        "Entscheidungsfindung optimieren 🚀"
    ];

    const [activeIndex, setActiveIndex] = React.useState(0);

    React.useEffect(() => {
        const interval = setInterval(() => {
            if (activeIndex === texts.length - 1) {
                clearInterval(interval);
            } else {
                setActiveIndex((previous) => {
                    return previous + 1 < texts.length ? previous + 1 : 0;
                });
            }

        }, 1500);

        return () => clearInterval(interval);
    });

    return (
        <Layout>
            <>
                <Loader size="xl" variant="bars" />
                <Text weight={700}>{texts[activeIndex]}</Text>
            </>
        </Layout>
    );
}