import React from "react";
import { useSearchParams } from "react-router-dom";
import { API, RemoteStateContext } from "../RemoteStateProvider";

export function useInvite() {
    const remoteState = React.useContext(RemoteStateContext);

    const [isValid, setIsValid] = React.useState(false);
    const [wasCheckend, setWasChecked] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true);
    const [searchParms] = useSearchParams();
    const invite = searchParms.get("invite") || "";

    React.useEffect(() => {
        setIsLoading(true);
        remoteState.inviteId = invite;
        API.getInvite(invite)
            .then((response) => {
                if (response.status === 'NOT_FOUND') {
                    setIsValid(false);
                }
                else {
                    setIsValid(true);
                }
                setWasChecked(true)
            })
            .catch(() => setIsValid(false))
            .finally(() => { setIsLoading(false); setWasChecked(true) });
    }, [remoteState, invite]);

    return {
        invite,
        inviteIsValid: isValid,
        inviteWasChecked: wasCheckend,
        isInviteCheckInProgress: isLoading
    }
}