import { useAuth0 } from "@auth0/auth0-react";
import { Button, Center, Col, Grid, Group, Image, Text, Title } from "@mantine/core";
import { RiLoginBoxLine, RiMailSendFill } from "react-icons/ri";

export function LandingPage() {

  const { loginWithRedirect } = useAuth0();

  return (
    <Center style={{ maxWidth: "80%" }}>
      <Grid style={{ margin: "0" }}>
        <Col span={4}>
          <Image src={`${process.env.PUBLIC_URL}/happy_news.svg`} />
        </Col>
        <Col
          span={8}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            paddingLeft: "2em",
            paddingRight: "2em",
          }}
        >
          <Title order={1}>
            Besser entscheiden mit <GradientText>decide.social</GradientText>
          </Title>
          <Text size="xl" style={{ padding: "3em", textAlign: "justify" }}>
            Das Tool decide.social befindet sich aktuell noch in einer
            geschlossenen Testphase. Wir freuen uns sehr über dein Interesse!
            <br />
            Falls auch du bei an der geschlossenen Testphase teilnehmen
            möchtest, klick einfach auf den Button 'Zugang anfragen' und schicke uns eine Email.
            Du erhälst dann eine Einladung, welche es dir ermöglicht das Tool
            schon während der Testphase zu verwenden.
          </Text>
          <Group>
            <Button
              variant="gradient"
              gradient={{ from: "#ab8fe2", to: "#c5a6ff", deg: 60 }}
              size="xl"
              leftIcon={<RiLoginBoxLine />}
              onClick={() => loginWithRedirect({ redirectUri: `${window.location.origin}/login/redirect_uri`, appState: { redirectTarget: "/dashboard" } })}>
              Login
            </Button>
            <Text>- oder -</Text>
            <Button
              variant="gradient"
              gradient={{ from: "teal", to: "blue", deg: 60 }}
              size="xl"
              leftIcon={<RiMailSendFill />}
              onClick={() => {
                const body = `Hallo, ich habe Interesse decide.social auszuprobieren. Bitte sendet mir einen Testzugang zu.`
                const emailAddress = "invite@decide.social";
                const subject = "Interesse an decide.social";
                const emailBody = body;
                window.location.href = `mailto:${emailAddress}?subject=${subject}&body=${emailBody}`;
              }}
            >
              Zugang anfragen
            </Button>
          </Group>
        </Col>
      </Grid>
    </Center>
  );
}

function GradientText({ children }: { children: string }) {
  return (
    <Text
      component="span"
      align="center"
      variant="gradient"
      gradient={{ from: "teal", to: "blue", deg: 60 }}
      size="xl"
      weight={700}
      style={{ fontFamily: "Greycliff CF, sans-serif", fontSize: "34pt" }}
    >
      {children}
    </Text>
  );
}
