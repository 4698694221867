import { useAuth0 } from "@auth0/auth0-react";

const REST_API_BASE_URL = process.env.REACT_APP_REST_API_URL || process.env.REACT_APP_API_URL || 'http://localhost:3000';

//TODO: extract api interfaces to separat directory/package
interface PreparedDecisionMessage {
    issue: string;
    passiveSolution: string;
    proposals: PreparedProposalMessage[];
}

interface PreparedProposalMessage {
    name: string;
    description: string;
}


export function useApi() {
    const { getAccessTokenSilently } = useAuth0();

    return {
        getDecisions: async () => get<{ id: string, issue: string, step: string }[]>("/decisions", await getAccessTokenSilently()),
        prepareDecision: async (prepareDecisionRequest: PreparedDecisionMessage) => post<PreparedDecisionMessage, any>("/decisions/prepare", await getAccessTokenSilently(), prepareDecisionRequest)
    };
}

async function get<T>(path: string, token: string): Promise<T> {
    const response = await fetch(`${REST_API_BASE_URL}${path}`, { headers: { 'authorization': `Bearer ${token}` } });

    if (!response.ok) {
        throw Error(response.statusText);
    }

    return response.json() as Promise<T>;
}

async function post<T, R>(path: string, token: string, body: T): Promise<R> {
    const response = await fetch(
        `${REST_API_BASE_URL}${path}`,
        {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'authorization': `Bearer ${token}` },
            body: JSON.stringify(body)
        });

    if (!response.ok) {
        throw Error(response.statusText);
    }

    return response.json() as Promise<R>;
}