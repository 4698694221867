import React from 'react';
import { Cell, Label, Pie, PieChart, Tooltip } from 'recharts';
import { DecisionSummary } from '../pages/DecisionSummary';
import { Participant, RemoteStateContext } from '../RemoteStateProvider';
import { selectColorFor } from './chart-helpers';


export function SpeechDistributionChart() {
  const remoteState = React.useContext(RemoteStateContext);
  const decisionSummary = new DecisionSummary(remoteState)
  const fakeData = generateFakeData(remoteState.participants);
  return (
    <PieChart width={800} height={500}>
      <Pie
        data={fakeData}
        dataKey="value"
        label={(props) => props.name}
        nameKey="name"
        cx="45%"
        cy="45%"
        innerRadius={120}
        outerRadius={150}
        isAnimationActive={false}>
        <Label value={`${decisionSummary.elapsedTime()} Minuten`} position='center' />

        {fakeData.map((entry, index) => {
          const fillColor = remoteState.participants.find(participant => participant.name.toLocaleLowerCase().trim() === entry.name.toLocaleLowerCase().trim())?.avatarColor || selectColorFor(index);
          return <Cell key={`cell-${index}`} fill={fillColor} />
        })}
      </Pie>
      <Tooltip formatter={(value: string) => `${value} Minuten`} />
    </PieChart>
  );
}

function generateFakeData(participants: Participant[]): { name: string, value: number }[] {
  const thilko = participants.find(participant => participant.name.toLocaleLowerCase().trim().includes("thilko"));
  const gregor = participants.find(participant => participant.name.toLocaleLowerCase().trim().includes("gregor"));
  const rene = participants.find(participant => participant.name.toLocaleLowerCase().trim().includes("ren"));
  const matthias = participants.find(participant => participant.name.toLocaleLowerCase().trim().includes("matthias"));

  //Generate predictable fake data for the demo.
  if (thilko && gregor && rene && matthias) {
    return [
      { name: thilko.name, value: 5 }, { name: gregor.name, value: 4 }, { name: matthias.name, value: 4 }, { name: rene.name, value: 2 }
    ];
  }
  return participants.map(participant => ({ name: participant.name, value: Math.floor(Math.random() * 9) + 1 }));
}