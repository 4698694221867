import { Grid, Text } from '@mantine/core';
import React from 'react';
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { DecisionSummary } from "../pages/DecisionSummary";
import { RemoteStateContext } from '../RemoteStateProvider';
import { selectColorFor } from './chart-helpers';

export function ResistanceChart({ decisionSummary }: { decisionSummary: DecisionSummary }) {
    const { participants } = React.useContext(RemoteStateContext);

    return (
        <Grid style={{ height: "100%" }}>
            <Grid.Col span={12}>
                <ResponsiveContainer width="100%">
                    <BarChart
                        data={decisionSummary.proposals()}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <XAxis dataKey="index" height={50} />
                        <YAxis />
                        <Tooltip labelFormatter={(label, payload) => { return parseInt(label, 10) ? decisionSummary.proposals()[parseInt(label, 10) - 1].name : label }} formatter={(value: string, name: string) => [value, name.replace("votes.", "")]} />
                        {decisionSummary.uniqueParticipantNames().map((participantName, index) => {
                            const fillColor = participants.find(participant => participant.name === participantName)?.avatarColor || selectColorFor(index);

                            return <Bar stackId="a" dataKey={'votes.' + participantName} fill={fillColor} />
                        })}
                    </BarChart>
                </ResponsiveContainer>
            </Grid.Col>
            <Grid.Col span={12}>
                <CustomLegend decisionSummary={decisionSummary} />
            </Grid.Col>
        </Grid>
    );
}

function CustomLegend({ decisionSummary }: { decisionSummary: DecisionSummary }) {
    return (
        <>
            {decisionSummary.proposals().map(proposal => <Text key={`legend-proposal-${proposal.index}`}>{proposal.index}. {proposal.name}</Text>)}
        </>
    );
}