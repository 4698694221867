import { Button, Center, Group, Modal, Stack, Textarea } from '@mantine/core'
import React, { ReactNode, useState } from 'react'
import { DecisionStep, RemoteStateContext } from '../RemoteStateProvider'
import { useNavigate, useParams } from 'react-router-dom'
import { useTheme } from '../theme'
import { useForm } from '@mantine/form'

export function Tools() {
  const { currentParticipant } = React.useContext(RemoteStateContext)

  return (
    <Center>
      {currentParticipant?.isModerator && <ModeratorTools />}
      {!currentParticipant?.isModerator && <ParticipantTools />}
    </Center>
  )
}

export const InlineToolButtonLayout: React.FC<{ children: ReactNode }> = (props) => {
  return (
    <Center inline>
      <Group spacing={'md'}>{props.children}</Group>
    </Center>
  )
}

//@ts-ignore
export function ParticipantTools() {
  const remoteState = React.useContext(RemoteStateContext)
  const { sessionId } = useParams()

  const navigate = useNavigate()

  switch (remoteState.step) {
    case DecisionStep.DECISION_MADE:
      return (
        <InlineToolButtonLayout>
          <Button
            variant="gradient"
            gradient={{ from: 'teal', to: 'blue', deg: 60 }}
            style={{ marginTop: '3em' }}
            onClick={() => {
              navigate(`/decision/${sessionId}/details`)
            }}
          >
            Details...
          </Button>
        </InlineToolButtonLayout>
      )
    default:
      return <></>
  }
}

export function ModeratorTools() {
  const remoteState = React.useContext(RemoteStateContext)
  const [opened, setOpened] = useState(false)
  const theme = useTheme()

  switch (remoteState.step) {
    case DecisionStep.PROPAGATE_PROBLEM_STATEMENT:
      return (
        <InlineToolButtonLayout>
          <Button variant="gradient" gradient={{ from: 'grape', to: 'blue', deg: 60 }} onClick={remoteState.startProposalCollection}>
            {'Vorschläge sammeln'}
          </Button>
        </InlineToolButtonLayout>
      )
    case DecisionStep.PROPOSAL_COLLECTION:
      return (
        <Center inline>
          <Button variant="gradient" gradient={{ from: 'grape', to: 'blue', deg: 60 }} onClick={remoteState.startInformationRound}>
            {'Informationsrunde starten'}
          </Button>
        </Center>
      )
    case DecisionStep.INFORMATION_ROUND:
      return (
        <InlineToolButtonLayout>
          <Button variant="gradient" gradient={{ from: 'grape', to: 'blue', deg: 60 }} onClick={remoteState.startOpinionRound}>
            {'Meinungsrunde starten'}
          </Button>
        </InlineToolButtonLayout>
      )
    case DecisionStep.OPINION_ROUND:
      return (
        <InlineToolButtonLayout>
          <WriteOpinion opened={opened} setOpened={setOpened} theme={theme} />{' '}
          <Button variant="gradient" gradient={{ from: 'grape', to: 'blue', deg: 60 }} onClick={remoteState.startVoting}>
            {'Abstimmung starten'}
          </Button>
        </InlineToolButtonLayout>
      )
    case DecisionStep.VOTING:
      const numberOfParticipantsVoted = remoteState.participants.filter((participant) => participant.hasVoted).length
      return (
        <InlineToolButtonLayout>
          <Button variant="gradient" gradient={{ from: 'grape', to: 'blue', deg: 60 }} onClick={remoteState.endVoting}>
            {`Abstimmung beenden (${numberOfParticipantsVoted}/${remoteState.participants.length})`}
          </Button>
        </InlineToolButtonLayout>
      )
    case DecisionStep.DECISION_MADE:
      return (
        <InlineToolButtonLayout>
          <Button variant="gradient" gradient={{ from: 'grape', to: 'blue', deg: 60 }}>
            Entscheidung dokumentieren
          </Button>
        </InlineToolButtonLayout>
      )
    default:
      return <></>
  }
}

export function WriteOpinion({ opened, setOpened }: { opened: boolean; setOpened: any; theme: any }) {
  const form = useForm({
    initialValues: {
      opinion: '',
    },
  })
  const remoteState = React.useContext(RemoteStateContext)

  return (
    <>
      <Modal opened={opened} onClose={() => setOpened(false)}>
        {
          <>
            <Stack style={{ width: '100%' }}>
              <form
                onSubmit={form.onSubmit((values) => {
                  remoteState.expressOpinion(remoteState.currentParticipant!.id, values.opinion)
                  form.reset()
                })}
              >
                <Textarea
                  label="Deine Meinung"
                  description="Beschreibe in kurzen Worten deine Meinung zu dem Thema"
                  required
                  minRows={5}
                  {...form.getInputProps('opinion')}
                />
                <Button type="submit" variant="light" color="red" size="lg">
                  Meinung erfassen
                </Button>
              </form>
            </Stack>
          </>
        }
      </Modal>
    </>
  )
}
