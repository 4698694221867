import { Affix, Avatar, Box, Button, Center, Dialog, Grid, SimpleGrid, Stack, Text, useMantineTheme } from '@mantine/core'
import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { IssueCard } from '../components/IssueCard'
import { SortedProposals } from '../components/SortedProposals'
import { RemoteStateContext } from '../RemoteStateProvider'
import { GrRobot } from 'react-icons/gr'

export function OpinionRound() {
  const remoteState = React.useContext(RemoteStateContext)
  const theme = useMantineTheme()
  const activeSpeaker = remoteState.participants.find((p) => p.id === remoteState.activeSpeaker?.id) || remoteState.participants[0]

  const [searchParams] = useSearchParams()
  const showInteruptButton = searchParams.get('showInteruptButton')

  return (
    <>
      {showInteruptButton && (
        <Affix position={{ bottom: 20, left: 20 }}>
          <Button onClick={remoteState.fakeInteruption}>Fake interuption.</Button>
        </Affix>
      )}
      <Dialog opened={remoteState.interruption} size="lg" radius="md">
        <Grid>
          <Grid.Col span={2}>
            <Center>
              <Avatar color="blue" radius="xl" size="lg">
                <GrRobot size={32} />
              </Avatar>
            </Center>
          </Grid.Col>
          <Grid.Col span={10}>
            <Text weight={800}>Matthias hat René unterbrochen!</Text>
            <Text>Bitte gebt euch gegenseitig genug Raum, sodass jeder seine Meinung mitteilen kann.</Text>
          </Grid.Col>
        </Grid>
      </Dialog>

      <Box
        sx={() => ({
          display: 'grid',
          gridTemplateColumns: '1fr ',
          gridTemplateRows: '1fr auto',
          height: 'calc(100vh - 220px)',
          width: '100vw',
          overflow: 'hidden',
        })}
      >
        <Stack
          p={30}
          spacing={'xl'}
          justify={'center'}
          align={'stretch'}
          style={{
            overflowX: 'hidden',
            overflowY: 'auto',
            boxShadow: '0 0 100px #D2CED8FF inset',
          }}
        >
          <IssueCard />
          <SimpleGrid
            breakpoints={[
              { minWidth: 'sm', cols: 2 },
              { minWidth: 'md', cols: 2 },
              { minWidth: 1200, cols: 3 },
            ]}
          >
            <SortedProposals />
          </SimpleGrid>
        </Stack>

        <Stack align={'center'} justify={'center'} style={{ borderTop: '1px solid #fff' }} pt={10} mb={30}>
          {activeSpeaker.id === remoteState.currentParticipant?.id && (
            <>
              <Text style={{ fontSize: '2em', color: theme.colors.dark[4] }}>
                Was ist deine Meinung, {remoteState.activeSpeaker?.name}?
              </Text>
              <Text style={{ fontSize: '0.9em', color: theme.colors.dark[3] }}>
                Teile nun der Gruppe deine persönliche Meinung zu dem Thema. Wenn du fertig bist klicke auf 'Nächster Sprecher'.
              </Text>
            </>
          )}

          {activeSpeaker.id !== remoteState.currentParticipant?.id && (
            <>
              <Text style={{ fontSize: '2em', color: theme.colors.dark[4] }}>{remoteState.activeSpeaker?.name} spricht</Text>
              <Text style={{ fontSize: '0.9em', color: theme.colors.dark[3] }}>
                Höre aufmerksam zu und gebe deinem:deiner Kolleg:in Raum die Gedanken auszusprechen.
              </Text>
            </>
          )}

          {(remoteState.currentParticipant?.isModerator || activeSpeaker.id === remoteState.currentParticipant?.id) && (
            <Button variant="gradient" gradient={{ from: 'teal', to: 'blue', deg: 60 }} onClick={remoteState.promptNextSpeaker}>
              {'Nächster Sprecher'}
            </Button>
          )}
        </Stack>
      </Box>
    </>
  )
}
