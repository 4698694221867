import React from 'react'
import { AskPermissionModal } from '../components/AskPermissionModal'
import { Navigation } from '../components/Navigation'
import { DecisionStep, RemoteStateContext } from '../RemoteStateProvider'
import { useSpeechRecognition } from '../hooks/useSpeechRecognition'
import { DecisionMade } from './DecisionMade'
import { InformationRound } from './InformationRound'
import { OpinionRound } from './OpinionRound'
import { PropagateProblemStatement } from './PropagateProblemStatement'
import { ProposalCollection } from './ProposalCollection'
import { Voting } from './Voting'
import { ParticipantsFooter } from '../components/ParticipantsFooter'
import { ModeratorTools } from '../components/Tools'
import { css, Global } from '@emotion/react'
import { Button, CopyButton, Group, Text } from '@mantine/core'
import { RiShareForward2Fill } from 'react-icons/ri'

export function SessionLayout() {
  const remoteState = React.useContext(RemoteStateContext)
  const { currentParticipant } = React.useContext(RemoteStateContext)

  const { permission } = useSpeechRecognition()

  return permission === 'pending' ? (
    <AskPermissionModal opened={permission === 'pending'} />
  ) : (
    <div className={'appGrid'}>
      {currentParticipant?.isModerator && (
        <div className="moderator-header">
          <Group position={'apart'} grow>
            <Group position={'left'}>
              <Text color={'white'} size={'sm'}>
                Du moderierst die Sitzung
              </Text>
              <CopyButton value={window.location.href}>
                {({ copied, copy }) => (
                  <Button variant="subtle" radius="xs" size={'sm'} rightIcon={<RiShareForward2Fill />} onClick={copy}>
                    {copied ? 'Link in Zwischenablage kopiert' : 'Einladungslink kopieren'}
                  </Button>
                )}
              </CopyButton>
            </Group>
            <ModeratorTools />
          </Group>
        </div>
      )}

      <div className={'header'}>
        <Navigation />
      </div>

      <div className="content">
        {remoteState.step === DecisionStep.PROPAGATE_PROBLEM_STATEMENT && <PropagateProblemStatement />}
        {remoteState.step === DecisionStep.INFORMATION_ROUND && <InformationRound />}
        {remoteState.step === DecisionStep.OPINION_ROUND && <OpinionRound />}
        {remoteState.step === DecisionStep.PROPOSAL_COLLECTION && <ProposalCollection />}
        {remoteState.step === DecisionStep.VOTING && <Voting />}
        {remoteState.step === DecisionStep.DECISION_MADE && <DecisionMade />}
      </div>

      <div className="participants">
        <ParticipantsFooter />
      </div>

      <Global
        styles={css`
          .appGrid {
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: auto auto 1fr;
            grid-template-areas:
              'moderator-header'
              'header'
              'content';

            height: 100vh;
            width: 100vw;

            overflow: hidden;

            background-image: linear-gradient(to top, #ebe7f1 0%, #eaf1fb 98%, #ffffff 100%);
          }

          .moderator-header {
            background-image: radial-gradient(ellipse at bottom, #ab8fe2 0%, #c5a6ff 50%);
            overflow: hidden;
            padding: 0.31rem 1rem;
          }

          .header {
            border-top: 1px solid white;
            grid-area: header;
            overflow: hidden;
            padding: 1rem;
            float: right;

            background-image: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
          }

          .content {
            grid-area: content;

            overflow-x: hidden;
            overflow-y: auto;

            padding-bottom: 137px;

            display: grid;
            place-items: center;

            background-image: linear-gradient(to bottom, #eaf1fb 0%, rgba(255, 255, 255, 0) 50px);
            background-attachment: local;
            background-position: top;
          }

          .participants {
            position: absolute;
            bottom: 0;
            text-align: center;
            width: 100%;

            overflow: visible;
            z-index: 9999;

            padding-bottom: 10px;

            pointer-events: none;

            background: radial-gradient(ellipse at bottom, rgb(0, 0, 50, 0.2) 0%, rgba(255, 255, 255, 0) 30%);
          }
        `}
      />
    </div>
  )
}
