import { Box, Button, Container, Group, Slider, Stack, Text } from '@mantine/core'
import React, { useMemo, useState } from 'react'
import { BsCheckSquare } from 'react-icons/bs'
import { MoodConfuzed, MoodHappy, MoodNeutral, MoodSad, MoodSmile } from 'tabler-icons-react'
import { Proposal, RemoteStateContext, Vote } from '../RemoteStateProvider'
import { ProposalCard } from '../components/ProposalCard'

export function Voting() {
  const remoteState = React.useContext(RemoteStateContext)
  const participantId = remoteState.currentParticipant?.id || ''
  const [participantHasVoted, setParticipantHasVoted] = React.useState(false)
  const [votes, setVotes] = React.useState(() =>
    remoteState.proposals.map((p) => {
      return { proposalId: p.id, rate: 1 }
    }),
  )

  return (
    <Box style={{ width: '56em' }}>
      <Stack spacing={'xl'}>
        {remoteState.proposals.map((p) => (
          <IdeaResistanceVoting
            proposal={{ ...p }}
            hasVoted={participantHasVoted}
            onSliderChanged={(value: number) => {
              const vote: Vote = votes.find((v) => v.proposalId === p.id) || { proposalId: '', rate: 0 }
              const index = votes.indexOf(vote)

              vote.rate = value

              votes[index] = vote
              setVotes(votes)
            }}
          />
        ))}
        <AddVotesButton
          hasVoted={participantHasVoted}
          clickHandler={() => {
            setParticipantHasVoted(true)
            remoteState.addVotes(votes, participantId)
          }}
        />
      </Stack>
    </Box>
  )
}

function AddVotesButton({ clickHandler, hasVoted }: { clickHandler: () => void; hasVoted: boolean }) {
  const leftIcon = hasVoted ? <BsCheckSquare /> : <></>
  const gradient = hasVoted ? { from: 'grey', to: 'grey' } : { from: 'teal', to: 'blue', deg: 60 }

  return (
    <Container>
      <Button
        leftIcon={leftIcon}
        loading={hasVoted}
        variant="gradient"
        gradient={gradient}
        size="md"
        style={{ marginTop: '2em' }}
        onClick={clickHandler}
      >
        {hasVoted ? 'Bitte auf die Anderen warten' : 'Bewertung abschließen'}
      </Button>
    </Container>
  )
}

interface IdeaResistanceVotingPropsType {
  proposal: Proposal
  onSliderChanged: (value: number) => void
  hasVoted: boolean
}

export function IdeaResistanceVoting({ proposal, onSliderChanged, hasVoted }: IdeaResistanceVotingPropsType) {
  const [selectedMarkValue, setSelectedMarkValue] = useState(1)

  const MARKS = useMemo(
    () => [
      { value: 1, thumbIcon: MoodHappy },
      { value: 2, thumbIcon: MoodSmile },
      { value: 3, thumbIcon: MoodNeutral },
      { value: 4, thumbIcon: MoodConfuzed },
      { value: 5, thumbIcon: MoodSad },
    ],
    [],
  )

  const findThumbIcon = useMemo(() => {
    const mark = MARKS.find((mark) => mark.value === selectedMarkValue)
    if (!mark) {
      return undefined
    }
    const iconComponent = mark.thumbIcon
    return iconComponent({ size: 32 })
  }, [MARKS, selectedMarkValue])

  const onChange = (value: number) => {
    setSelectedMarkValue(value)
    onSliderChanged(value)
  }

  return (
    <ProposalCard {...proposal}>
      <Slider
        styles={() => ({
          thumb: {
            width: 42,
            height: 42,
          },
        })}
        defaultValue={0}
        disabled={hasVoted}
        label={null}
        size="xl"
        thumbChildren={findThumbIcon}
        key={proposal.id}
        onChange={onChange}
        marks={MARKS}
        max={5}
        min={1}
        color={'blue'}
      />
      <Group position="apart">
        <Text size={'sm'} color="blue">
          kein Widerstand
        </Text>
        <Text size={'sm'} color="blue">
          hoher Widerstand
        </Text>
      </Group>
    </ProposalCard>
  )
}
