import React from 'react'
import { CloseButton, Group, Paper, Stack, Text, Tooltip } from '@mantine/core'
import { IconBulb } from '@tabler/icons'
import { Proposal, RemoteStateContext } from '../RemoteStateProvider'

export const ProposalCard = ({
  id,
  name,
  description,
  children,
  passiveSolution,
  solutionNumber,
}: Proposal & {
  children: React.ReactNode
}) => {
  const remoteState = React.useContext(RemoteStateContext)

  const nameLabel = name ? name : passiveSolution ? 'Passivlösung' : 'Vorschlag ' + solutionNumber
  return (
    <Paper p="md" radius="md" key={id}>
      <Stack>
        <Group position="apart">
          <Group align="flex-end" spacing="xs">
            <IconBulb size={30} stroke={1.5} color={'grey'} />
            <Text weight={500}>{nameLabel}</Text>
          </Group>
          {remoteState.currentParticipant?.isModerator && (
            <Tooltip label={'Als Moderator:in kannst du eine Idee aus der Sitzung entfernen'}>
              <CloseButton
                color="purple"
                onClick={() => {
                  window.confirm(`Die Idee "${nameLabel}" wirklich entfernen?`) && remoteState.deleteProposal(id)
                }}
                variant={'gradient'}
                gradient={{ from: 'grape', to: 'blue', deg: 60 }}
              />
            </Tooltip>
          )}
        </Group>

        <Text color="dimmed" size="sm" align={'left'}>
          {description}
        </Text>

        {children}
      </Stack>
    </Paper>
  )
}
