import { Accordion, Button, Col, Divider, Grid, Group, ScrollArea, Table, Text } from '@mantine/core'
import React from 'react'
import { BarChart, XAxis, YAxis, Bar, Tooltip } from 'recharts'
import { Question, RemoteStateContext } from '../RemoteStateProvider'
import { DecisionSummary, ParticipantsVote, ProposalSummary } from './DecisionSummary'
import { Heart } from 'tabler-icons-react'
import { Duration } from 'luxon'
import { useNavigate } from 'react-router-dom'

export function DecisionDetails() {
  const remoteState = React.useContext(RemoteStateContext)
  const decisionSummary = new DecisionSummary(remoteState)
  const proposals = decisionSummary.proposals()
  const navigate = useNavigate()

  return (
      <Grid style={{ minWidth: '90%', margin: '1em 3em 1em 3em' }}>
        <Divider my="md" label="Übersicht Teilnehmer" labelPosition="center" variant="dashed" />
        <Col span={12}>
          <ParticipantsTable decisionSummary={decisionSummary} />
        </Col>

        <Divider my="md" label="Übersicht Vorschläge" labelPosition="center" />

        <Col span={12}>
          <ProposalTable data={proposals} />
        </Col>

        <Divider my="md" label="Übersicht Fragen" labelPosition="center" />

        <Col span={12}>
          <QuestionsTable data={remoteState.questions} />
        </Col>

        <Divider my="md" label="Weitere Details" labelPosition="center" />

        <Col span={12}>
          <ProposalDetails decisionSummary={decisionSummary} />
        </Col>

        <Col span={12}>
          <Button
            onClick={() => {
              navigate(-1)
            }}
          >
            Zurück
          </Button>
        </Col>
      </Grid>
  )
}

//@ts-ignore
function ProposalDetails({ decisionSummary }: { decisionSummary: DecisionSummary }) {
  const items = decisionSummary.proposals().map((item: ProposalSummary) => (
    <Accordion.Item value={item.id} key={item.name}>
      <Accordion.Control>
        <AccordionLabel description={item.description} label={item.name} />
      </Accordion.Control>
      <Accordion.Panel>
        <Grid>
          <Col span={6}>
            <VotingsTable data={item.allVotes} />
          </Col>

          <Col span={6}>
            <BarChart id="bar" data={item.distribution} width={500} height={200}>
              <XAxis dataKey="name" />
              <YAxis tick={false} />
              <Tooltip />
              <Bar dataKey="count" fill="#a7befe" />
            </BarChart>
          </Col>
        </Grid>
      </Accordion.Panel>
    </Accordion.Item>
  ))

  return (
    <Accordion chevronPosition="right" variant="contained">
      {items}
    </Accordion>
  )
}

interface AccordionLabelProps {
  label: string
  description: string
}

function AccordionLabel({ label, description }: AccordionLabelProps) {
  return (
    <Group noWrap>
      <Heart size={30} strokeWidth={3} color={'#d2b179'} />
      <div>
        <Text>{label}</Text>
        <Text size="sm" color="dimmed" weight={400}>
          {description}
        </Text>
      </div>
    </Group>
  )
}

interface ProposalTableProps {
  data: {
    name: string
    sum: number
    sumAverage: string
    sumRelative: string
    distribution: any
  }[]
}

export function ProposalTable({ data }: ProposalTableProps) {
  const rows = data.map((row) => {
    return (
      <tr key={row.name}>
        <td>
          <Text style={{ textAlign: 'left' }}>{row.name}</Text>
        </td>
        <td>
          <Text style={{ textAlign: 'left' }}>{row.sum}</Text>
        </td>
        <td>
          <Text style={{ textAlign: 'left' }}>{row.sumAverage}</Text>
        </td>
        <td>
          <Text style={{ textAlign: 'left' }}>{row.sumRelative}</Text>
        </td>
      </tr>
    )
  })

  return (
    <ScrollArea>
      <Table verticalSpacing="xs" highlightOnHover>
        <thead>
          <tr>
            <th>Name</th>
            <th>Summe Widerstand</th>
            <th>Ø Widerstand</th>
            <th>% Widerstand</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </ScrollArea>
  )
}

interface QuestionsTableProps {
  data: Question[]
}

export function QuestionsTable({ data }: QuestionsTableProps) {
  const rows = data.map((row) => {
    return (
      <tr key={row.id}>
        <td>
          <Text style={{ textAlign: 'left' }}>{row.description}</Text>
        </td>
        <td>
          <Text style={{ textAlign: 'left' }}>{row.answer}</Text>
        </td>
      </tr>
    )
  })

  return (
    <ScrollArea>
      <Table verticalSpacing="xs" highlightOnHover>
        <thead>
          <tr>
            <th>Frage</th>
            <th>Antwort</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </ScrollArea>
  )
}

interface ParticipantsTableProps {
  decisionSummary: DecisionSummary
}

export function ParticipantsTable({ decisionSummary }: ParticipantsTableProps) {
  const rows = decisionSummary.participants().map((row) => {
    return (
      <tr key={row.participantName}>
        <td>
          <Text style={{ textAlign: 'left' }}>{row.participantName}</Text>
        </td>
        <td>
          <Text style={{ textAlign: 'left' }}>{Duration.fromISO(row.speechLog.totalSpeechTime).as('minutes')} Minuten</Text>
        </td>
        <td>
          <Text style={{ textAlign: 'left' }}>N/A</Text>
        </td>
        <td>
          <Text style={{ textAlign: 'left' }}>{row.opinion ? row.opinion : 'N/A'}</Text>
        </td>
      </tr>
    )
  })

  return (
    <ScrollArea>
      <Table verticalSpacing="xs" highlightOnHover>
        <thead>
          <tr>
            <th>Name</th>
            <th>Redezeit Gesamt</th>
            <th>Redezeit %</th>
            <th>Meinung</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </ScrollArea>
  )
}

interface VotingsTableProps {
  data: ParticipantsVote[]
}

export function VotingsTable({ data }: VotingsTableProps) {
  const rows = data.map((row) => {
    return (
      <tr key={row.participantName}>
        <td>
          <Text style={{ textAlign: 'left' }}>{row.participantName}</Text>
        </td>
        <td>
          <Text style={{ textAlign: 'left' }}>{row.rate}</Text>
        </td>
      </tr>
    )
  })

  return (
    <ScrollArea>
      <Table verticalSpacing="xs" highlightOnHover>
        <thead>
          <tr>
            <th>Teilnehmer</th>
            <th>Wertung</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </ScrollArea>
  )
}
