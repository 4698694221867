import { useAuth0 } from "@auth0/auth0-react";
import { Center, Loader } from "@mantine/core";
import React from "react";
import { useNavigate } from "react-router-dom";

export function LoginRedirect() {

    const { handleRedirectCallback } = useAuth0();
    const navigate = useNavigate();


    React.useEffect(() => {
        const handleRedirect = async () => {
            const { appState } = await handleRedirectCallback();

            if (appState?.redirectTarget) {
                console.debug(`Login callback invoked, redirecting to ${appState.redirectTarget}`);
                navigate(appState.redirectTarget);
            }

        }
        handleRedirect();
    }, [handleRedirectCallback, navigate]);

    return <Center><Loader /></Center>;
}