import { showNotification } from '@mantine/notifications';
import React from "react";
import hark, { Harker } from '../hark/hark';
import { RemoteStateContext } from "../RemoteStateProvider";

// TODO:
// [x] refactor current state to use hook, call hook in SessionLayout
// [x] Add modal with explanation while requesting permissions
// [x] add notification with succes/error
// [ ] check if browser supports media api
// [ ] add sanity check for microphone
// [ ] add microphone calibration

const PERMISSION_STORAGE_KEY = 'permission:media:audio';

const Permissions = {
    unknown: 'unknown',
    denied: 'denied',
    pending: 'pending',
    granted: 'granted'
} as const;

interface SpeechRecognitionState {
    isSpeaking: boolean;
    audioStream: MediaStream | null;
    permission: string;
}

export function useSpeechRecognition(): SpeechRecognitionState {
    const remoteState = React.useContext(RemoteStateContext);

    const initialPermission = localStorage.getItem(PERMISSION_STORAGE_KEY) || Permissions.pending;

    const [speechAnalyzer, setSpeachAnalyzer] = React.useState<Harker | null>(null);
    const [speechRecognitionState, setState] = React.useState<SpeechRecognitionState>({
        isSpeaking: false,
        audioStream: null,
        permission: initialPermission
    });

    React.useEffect(() => {
        // get permission/media stream
        navigator.mediaDevices.getUserMedia({
            audio: true,
            video: false,
        }).then((stream) => {
            // configure analyzer
            const harkAnalyzer = hark(stream);
            harkAnalyzer.setInterval(250);
            harkAnalyzer.setThreshold(-40);
            setSpeachAnalyzer(harkAnalyzer);
            setState((previos) => ({ ...previos, audioStream: stream, permission: 'granted' }));
            localStorage.setItem(PERMISSION_STORAGE_KEY, Permissions.granted);
            showNotification({
                message: "Mikrofon erfolgreich aktiviert.",
                autoClose: 3500,
                color: "teal",
            });
        }).catch((err) => {
            console.error(`${err.name} : ${err.message}`);
            setState((previos) => ({ ...previos, audioStream: null, permission: 'denied' }));
            if (!localStorage.getItem(PERMISSION_STORAGE_KEY)) {
                showNotification({
                    message: "Mikrofon konnte nicht aktiviert werden.",
                    autoClose: 3500,
                    color: "red",
                });
            }
            localStorage.setItem(PERMISSION_STORAGE_KEY, Permissions.denied);
        });
    }, []);

    React.useEffect(() => {
        if (speechAnalyzer) {
            speechAnalyzer.on('speaking', function () {
                remoteState.participantStartsSpeaking(
                    remoteState.currentParticipant?.id || '',
                );
                setState((previos) => ({ ...previos, isSpeaking: true }));
            });

            speechAnalyzer.on('stopped_speaking', function () {
                remoteState.participantStoppedSpeaking(
                    remoteState.currentParticipant?.id || '',
                );
                setState((previos) => ({ ...previos, isSpeaking: false }));
            });
        }

        return () => {
            if (speechAnalyzer) {
                speechAnalyzer.offAll();
            }
        }
    }, [speechAnalyzer, remoteState]);

    return speechRecognitionState;
}