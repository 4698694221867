import { DateTime, Duration } from 'luxon';
import { ProposalQuality, RemoteState, Result } from '../RemoteStateProvider';

export interface ProposalSummary {
  id: string;
  index: number;
  description: string;
  name: string;
  sum: number;
  sumRelative: string;
  sumAverage: string;
  allVotes: ParticipantsVote[];
  votes: any;
  distribution: any;
  passiveSolution: boolean;
  quality: ProposalQuality;
}

export interface ParticipantsVote {
  rate: number;
  participantId: string;
  participantName: string;
}

export class DecisionSummary {
  _remoteState: RemoteState;

  constructor(remoteState: RemoteState) {
    this._remoteState = remoteState;
  }

  proposals(): ProposalSummary[] {
    return this.sortedProposals().map((proposal, index) => {
      return {
        id: proposal.id,
        index: index + 1,
        name: proposal.passiveSolution
          ? 'Passivlösung'
          : proposal.name,
        description: proposal.description,
        sum: proposal.sum,
        passiveSolution: proposal.passiveSolution,
        allVotes: proposal.votes,
        votes: proposal.votes.reduce((previous: any, current) => {
          previous[current.participantName] = current.rate;
          return previous;
        }, {}),
        sumRelative: (
          (proposal.sum / (this._remoteState.participants.length * 5)) *
          100
        ).toFixed(1),
        sumAverage: (proposal.sum / this._remoteState.participants.length).toFixed(1),
        distribution: this.voteDistribution(proposal),
        quality: proposal.quality,
      };
    });
  }

  elapsedTime() {
    const elapsedTime = DateTime.fromISO(
      this._remoteState.summary?.endTimestamp,
    )
      .diff(DateTime.fromISO(this._remoteState.summary.startTimestamp))
      .as('minutes')
      .toFixed(0);

    if (elapsedTime === '0') {
      return '< 1';
    } else {
      return elapsedTime;
    }
  }

  uniqueParticipantNames() {
    let participantNames = this._remoteState.summary.proposals
      .map((d) => d.votes.map((v) => v.participantName))
      .flat();

    participantNames = participantNames.filter((item, pos) => {
      return participantNames.indexOf(item) === pos;
    });

    return participantNames;
  }

  acceptedProposal(): ProposalSummary {
    return this.proposals()[0];
  }

  sortedProposals() {
    return (
      this._remoteState.summary?.proposals.sort((r1, r2) => r1.sum - r2.sum) ||
      []
    );
  }

  questions() {
    return this._remoteState.summary?.questions;
  }

  participants() {
    return this._remoteState.summary.participants;
  }

  opinions(): string[] {
    return this._remoteState.summary.participants
      .filter((p) => p.opinion)
      .map((p) => p.opinion);
  }

  voteDistribution(result: Result) {
    return result.votes.reduce(
      (previous, current) => {
        let b = previous.find((f) => f.name === current.rate.toString());
        if (b) {
          b.count = b.count + 1;
        }
        return previous;
      },
      [
        { name: '1', count: 0 },
        { name: '2', count: 0 },
        { name: '3', count: 0 },
        { name: '4', count: 0 },
        { name: '5', count: 0 },
      ],
    );
  }

  speechDistribution() {
    return this._remoteState.summary?.participants.map((p) => {
      return {
        participantName: p.participantName,
        totalSpeechTime:
          Duration.fromISO(p.speechLog.totalSpeechTime).as('minutes') + 50,
      };
    });
  }
}
