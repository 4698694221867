import { Auth0Provider } from '@auth0/auth0-react'
import { MantineProvider } from '@mantine/core'
import { NotificationsProvider } from '@mantine/notifications'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { BrowserRouter } from 'react-router-dom'
import './App.css'
import { DecideSocialRoutes } from './components/Routes'
import { RemoteStateProvider } from './RemoteStateProvider'
import { useTheme } from './theme'

function App() {
  const theme = useTheme();
  const queryClient = new QueryClient();

  return (
    <BrowserRouter>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN || ""}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ""}
        redirectUri={window.location.origin}
        audience="https://api.decide.social"
        useRefreshTokens
        cacheLocation='localstorage'
        scope="openid profile email offline_access app_data"
      >
        <QueryClientProvider client={queryClient}>
        <MantineProvider theme={theme} withNormalizeCSS withGlobalStyles>
          <NotificationsProvider>
            <RemoteStateProvider>
              <DecideSocialRoutes />
            </RemoteStateProvider>
          </NotificationsProvider>
        </MantineProvider>
        </QueryClientProvider>
      </Auth0Provider>

    </BrowserRouter>
  )
}

export default App;
