import { Group, Paper, Stack, Text } from '@mantine/core'
import React from 'react'
import { RemoteStateContext } from '../RemoteStateProvider'
import { IconBolt } from '@tabler/icons'

export function IssueCard() {
  const remoteState = React.useContext(RemoteStateContext)

  return (
    <Paper p={'md'}>
      <Stack>
        <Group align="flex-end" spacing="xs">
          <IconBolt size={30} color={'grey'} />
          <Text weight={500}>Anliegen</Text>
        </Group>
        <Text align={'left'}>{remoteState.issue}</Text>
      </Stack>
    </Paper>
  )
}
