import { GetTokenSilentlyOptions, useAuth0 } from "@auth0/auth0-react";
import { useInvite } from "./useInvite";

// The state of the websocket state is not controlled by React, where as the authentication state is
// controlled by React. The function to get an access token from Auth0 is controlled by React, however
// we need it to to authenticate the websocket connection later in the applications lifecycle.
// This global mutable variable is used to share the reference to the funtion that can provide
// access tokens from the React manged state to the websocket state.
export let GET_ACCESS_TOKEN: {
    (options: GetTokenSilentlyOptions & { detailedResponse: true; }): Promise<GetTokenSilentlyOptions>;
    (options?: GetTokenSilentlyOptions | undefined): Promise<string>;
    (options: GetTokenSilentlyOptions): Promise<string | GetTokenSilentlyOptions>;
};

export function SET_GET_ACCESS_TOKEN(getAccessToken: {
    (options: GetTokenSilentlyOptions & { detailedResponse: true; }): Promise<GetTokenSilentlyOptions>; (options?:
        GetTokenSilentlyOptions | undefined): Promise<string>; (options: GetTokenSilentlyOptions): Promise<string | GetTokenSilentlyOptions>;
}) {
    GET_ACCESS_TOKEN = getAccessToken;
}

export function useAuthentication() {
    const {
        inviteIsValid,
        inviteWasChecked,
        isInviteCheckInProgress
    } = useInvite();
    const { isAuthenticated, isLoading: auth0ChechInProgress, getAccessTokenSilently } = useAuth0();

    const isLoading = isInviteCheckInProgress || auth0ChechInProgress;

    const isInviteValid = inviteWasChecked && inviteIsValid;
    const canProgress = isInviteValid || isAuthenticated;

    if (isAuthenticated) {
        GET_ACCESS_TOKEN = getAccessTokenSilently;
    }

    return {
        isLoading,
        canProgress
    }
}