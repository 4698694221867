import { MantineTheme, useMantineTheme } from "@mantine/core";

export function useTheme(): MantineTheme {
    const theme = useMantineTheme();
    // theme.colorScheme = "dark";
    theme.colors = {
        ...theme.colors,
        secondary: theme.colors.yellow
    }
    theme.primaryColor = 'indigo';
    theme.primaryShade = 5;
    theme.fontFamily = "Exo"
    return theme;
}