import React from 'react'
import { RemoteStateContext } from '../RemoteStateProvider'
import { DateTime } from 'luxon'
import { ProposalCard } from './ProposalCard'

export function SortedProposals() {
  const remoteState = React.useContext(RemoteStateContext)

  return (
    <>
      {remoteState.proposals
        .sort((a, b) => {
          return DateTime.fromISO(a.createdAt).toMillis() - DateTime.fromISO(b.createdAt).toMillis()
        })
        .map((p, index) => {
          return <ProposalCard key={index} {...p} children={''} />
        })}
    </>
  )
}
